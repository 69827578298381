import common from './common'
import home from './home'
import login from './login'
import notifications from './notifications'
import passwordReset from './passwordReset'
import editCampaign from './editCampaign'
import formations from './formations'
import campaignDetails from './campaignDetails'
import users from './users'

interface Translations {
  [key: string]: {
    fr: string
    en: string
  }
}

const tr: Translations = {
  ...common,
  ...home,
  ...login,
  ...notifications,
  ...passwordReset,
  ...editCampaign,
  ...formations,
  ...campaignDetails,
  ...users,
}

export default tr
