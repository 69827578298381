export default {
  USER_MAX_REACHED: {
    fr:
      "Nombre d'utilisateurs maximum atteint. Veuillez contacter le support pour augmenter la limite.",
    en:
      "Nombre d'utilisateurs maximum atteint. Veuillez contacter le support pour augmenter la limite.",
  },
  UNKNOWN_FILE_TYPE: {
    fr: 'Type de fichier non pris en charger.',
    en: 'Type de fichier non pris en charger.',
  },
  'translation missing: fr.activerecord.errors.messages.record_invalid': {
    fr: 'Données incorrectes',
    en: 'Données incorrectes',
  },
}
