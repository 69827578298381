import axios, { AxiosResponse } from 'axios'
import jwtDecode from 'jwt-decode'
import { Method, Route, BASE_URL } from '../routes'
import { JWT } from '../types/auth'
import i18n, { t, stringToLng, Lng } from './i18n'
import store, { Message, Stored } from './store'
import humps from 'humps'

interface Request {
  resolve: any
  reject: any
  route: Route
}

type ErrorHandler = (err: string | undefined) => void
type ResponseHandler = (resp: AxiosResponse) => void

class HttpClient {
  public pendingRequests: Request[]
  public isRefreshing?: boolean
  public jwt?: JWT
  public rawJwt?: string
  public token?: string
  public locale?: Lng

  constructor() {
    const savedState = JSON.parse(localStorage.getItem('state')) || {}

    this.pendingRequests = []
    this.jwt = savedState[Stored.JWT]
    this.rawJwt = savedState[Stored.RawJWT]
    this.locale = savedState[Stored.Language]
    // this.token = savedState[Stored.RefreshToken]
  }

  public async req(
    route: Route,
    errorHandler?: ErrorHandler,
    responseHandler?: ResponseHandler
  ) {
    let { params, method, data, auth, file, downloadFilename } = route
    const headers: any = {}

    if (auth) {
      if (this.isRefreshing) {
        return this.setPendingRequest(route)
      }
      if (!this.jwt) {
        store.notify(Message.NeedAuth)
        return Promise.reject('Please log in to continue') // TODO I18n
      }
      headers['Authorization'] = `Bearer ${this.rawJwt}`
      // headers['X-Locale'] = this.locale ? this.locale : 'fr'
    }

    if (file) {
      const oldData = data || {}

      data = new FormData()
      data.append('file', file)
      Object.keys(oldData).forEach((k) => data.append(k, oldData[k]))
    }

    try {
      return [Method.Get, Method.Delete].includes(method)
        ? (axios[method] as any)(this.genUrl(route), {
            params: humps.decamelizeKeys(params),
            headers,
            responseType: downloadFilename ? 'arraybuffer' : undefined,
          })
            .then((e: any) => {
              if (responseHandler) {
                responseHandler(e)
              }
              if (downloadFilename) {
                let blob = new Blob([e.data])
                let link = document.createElement('a')

                link.href = window.URL.createObjectURL(blob)
                link.download = downloadFilename
                link.click()
              }
              return humps.camelizeKeys(e.data)
            })
            .catch((err: { response?: AxiosResponse }) =>
              this.handleError(err, errorHandler)
            )
        : (axios[method] as any)(
            this.genUrl(route),
            file ? data : humps.decamelizeKeys(data),
            {
              params: humps.decamelizeKeys(params),
              headers,
            }
          )
            .then((e: any) => {
              if (responseHandler) {
                responseHandler(e)
              }
              return humps.camelizeKeys(e.data)
            })
            .catch((err: { response?: AxiosResponse }) =>
              this.handleError(err, errorHandler)
            )
    } catch (err) {
      this.handleError(err, errorHandler)
    }
  }

  public storeCreds(token: string): [string, JWT] {
    const state = JSON.parse(localStorage.getItem('state')) || {}

    this.jwt = jwtDecode<JWT>(token)
    this.rawJwt = token
    state[Stored.JWT] = this.jwt
    state[Stored.RawJWT] = this.rawJwt
    i18n.setLng(stringToLng(this.jwt.language))
    store.update(Stored.Language, stringToLng(this.jwt.language))
    localStorage.setItem('state', JSON.stringify(state))
    return [this.rawJwt, this.jwt]
  }

  public updateLocale(locale: string) {
    const state = JSON.parse(localStorage.getItem('state')) || {}
    const lngLocale = stringToLng(locale)

    console.log("Locale: ", lngLocale)

    this.locale = lngLocale
    state[Stored.Language] = lngLocale
    console.log("locale in httpclients = ", lngLocale)
    i18n.setLng(lngLocale)
    store.update(Stored.Language, lngLocale)
    localStorage.setItem('state', JSON.stringify(state))
  }

  private handleError(
    error: { response?: AxiosResponse },
    handler?: ErrorHandler
  ) {
    const err =
      (error.response && error.response.data && error.response.data.error) ||
      undefined

    if (error.response && error.response.status == 401) {
      store.notify(Message.NeedAuth)
      throw error
    }

    if (handler) {
      handler(err)
    } else if (error.response) {
      store.notify(Message.Error, err ? t(err) : t('Erreur inconnue'))
    } else {
      store.notify(Message.Error, t('Erreur inconnue'))
    }
    throw error
  }

  // private refreshJwt() {
  //   if (!this.token) {
  //     return store.notify(Message.NeedAuth)
  //   }

  //   this.req(ROUTES.REFRESH_JWT(this.token))
  //     .then((res: AuthResponse) => {
  //       this.isRefreshing = false
  //       this.storeCreds(res)
  //       this.pendingRequests.forEach((r) => {
  //         this.req(r.route).then(r.resolve).catch(r.reject)
  //       })
  //     })
  //     .catch(() => {
  //       this.pendingRequests = []
  //       store.notify(Message.NeedAuth)
  //     })
  // }

  private genUrl(route: Route): string {
    return route.extern ? route.path : BASE_URL + route.path
  }

  private setPendingRequest(route: Route): Promise<any> {
    const promise = new Promise((resolve: any, reject: any) => {
      this.pendingRequests.push({ resolve, reject, route })
    })

    return promise
  }
}

const httpClient = new HttpClient()

export default httpClient
