import { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useForceUpdate = () => {
  const [value, setValue] = useState(0)

  return () => setValue((value) => ++value)
}

export const usePrevious = (value: any) => {
  const ref = useRef(value)

  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const unique = (value: any, index: number, self: any[]) => {
  return self.indexOf(value) == index
}
