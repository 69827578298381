import React, { useState } from 'react'
import './Accordion.scss'

interface Props {
  className?: string
  titleRenderer: () => JSX.Element
  children: JSX.Element | JSX.Element[]
  openByDefault?: boolean
}

export default (props: Props) => {
  const [expanded, setExpanded] = useState(props.openByDefault)

  return (
    <div className={`Accordion${props.className ? ` ${props.className}` : ''}`}>
      <div
        className="Accordion__title"
        onClick={() => setExpanded((prev) => !prev)}
      >
        {props.titleRenderer()}
      </div>
      {expanded && <div className="Accordion__content">{props.children}</div>}
    </div>
  )
}
