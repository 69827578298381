import React, { useContext, useState } from 'react'
import './CampaignCreationTitle.scss'
import InputWithLabel from '../../../components/InputWithLabel'
import { t } from '../../../services/i18n'
import Button from '../../../components/Button'
import { StepperContext } from 'react-material-stepper'
import Campaign from '../../../types/campaign'

interface Props {
  campaign: Campaign
}

export default (props: Props) => {
  const { resolve, getData } = useContext(StepperContext)
  const [campaign, setCampaign] = useState(
    getData('title')?.campaign || props.campaign
  )

  const goToNextStep = () => {
    resolve({ campaign })
  }

  return (
    <div className="CampaignCreationTitle">
      <div className="section__title">{t('Définir la formation')}</div>
      <div className="section__body">
        <InputWithLabel
          label={t('Titre')}
          value={campaign.title}
          onChange={(v) => setCampaign({ ...campaign, title: v })}
        />
        <InputWithLabel
          label={t('Description')}
          value={campaign.description}
          onChange={(v) => setCampaign({ ...campaign, description: v })}
        />
        <div className="section__actions">
          <Button
            title={t('Suivant')}
            onClick={goToNextStep}
            disabled={!campaign.title}
          />
        </div>
      </div>
    </div>
  )
}
