export default {
    "Définir la formation": {
        fr: "Définir la formation",
        en: "Create a campaign"
    },
    "Titre": {
        fr: "Titre",
        en: "Title"
    },
    "Description": {
        fr: "Description",
        en: "Description"
    },
    "Suivant": {
        fr: "Suivant",
        en: "Next"
    },
    "Choisir le modèle": {
        fr: "Choisir le modèle",
        en: "Select the model"
    },
    "Modèle de formation": {
        fr: "Modèle de formation",
        en: "Training model"
    },
    "Palier de déblocage de la certification (en %)": {
        fr: "Palier de déblocage de la certification (en %)",
        en: "Completion rate before certification (in %)"
    },
    "Nombre max. d'erreurs pour valider la certification": {
        fr: "Nombre max. d'erreurs pour valider la certification",
        en: "Maximum number of errors allowed to complete certification"
    },
    " sur ": {
        fr: " sur ",
        en: " of "
    },
    "Nombre de question pour la certification": {
        fr: "Nombre de question pour la certification",
        en: "Number of questions in the certification test"
    },
    "Nombre de tentatives de certification": {
        fr: "Nombre de tentatives de certification",
        en: "Number of certification attempts"
    },
    "essai(s) par": {
        fr: "essai(s) par",
        en: "attempt(s) per"
    },
    "Jour": {
        fr: "Jour",
        en: "Day"
    },
    "Semaine": {
        fr: "Semaine",
        en: "Week"
    },
    "Mois": {
        fr: "Mois",
        en: "Month"
    },
    "Paramètres de la campagne": {
        fr: "Paramètres de la campagne",
        en: "Campaign settings"
    },
    "Assigner les apprenants": {
        fr: "Assigner les apprenants",
        en: "Assign users"
    },
    "Assigner des apprenants": {
        fr: "Assigner des apprenants",
        en: "Assign users"
    },
    "Ajouter un apprenant existant": {
        fr: "Ajouter un apprenant existant",
        en: "Add an existing user"
    },
    "Ajouter": {
        fr: "Ajouter",
        en: "Add"
    },
    "Ajouté": {
        fr: "Ajouté",
        en: "Added"
    },
    "ou": {
        fr: "Ou",
        en: "or"
    },
    "Ajouter un apprenant manuellement": {
        fr: "Ajouter un apprenant manuellement",
        en: "Add a user manually"
    },
    "Liste des utilisateurs": {
        fr: "Liste des utilisateurs",
        en: "User list"
    },
    "Prénom": {
        fr: "Prénom",
        en: "First name"
    },
    "Nom": {
        fr: "Nom",
        en: "Last name"
    },
    "Courriel": {
        fr: "Courriel",
        en: "Email"
    },
    "Rôle": {
        fr: "Rôle",
        en: "Role"
    },
    "Confirmation du mot de passe": {
        fr: "Confirmation du mot de passe",
        en: "Confirm password"
    },
    "Téléphone portable": {
        fr: "Téléphone portable",
        en: "Mobile phone"
    },
    "Fonction": {
        fr: "Fonction",
        en: "Function"
    },
    "Filiale": {
        fr: "Filiale",
        en: "Branch"
    },
    "Service": {
        fr: "Service",
        en: "Service"
    },
    "Manager direct": {
        fr: "Manager direct",
        en: "Direct manager"
    },
    "Région 1": {
        fr: "Région 1",
        en: "Region 1"
    },
    "Région 2": {
        fr: "Région 2",
        en: "Region 2"
    },
    "Champ libre 1": {
        fr: "Champ libre 1",
        en: "Free field 1"
    },
    "Champ libre 2": {
        fr: "Champ libre 2",
        en: "Free field 2"
    },
    "Planifier la formation": {
        fr: "Planifier la formation",
        en: "Schedule the campaign"
    },
    "Dates de début et fin de formation": {
        fr: "Dates de début et fin de formation",
        en: "Start and end dates"
    },
    "Heure de lancement": {
        fr: "Heure de lancement",
        en: "Start time"
    },
    "Heure de fin": {
        fr: "Heure de fin",
        en: "End time"
    },
    "Email de lancement": {
        fr: "Email de lancement",
        en: "Enrollment email"
    },
    "Inscription par mail automatique Vigiware": {
        fr: "Inscription par mail automatique Vigiware",
        en: "Enrollment via Vigiware platform"
    },
    "Inscription par mail envoyé par mes soins": {
        fr: "Inscription par mail envoyé par mes soins",
        en: "Send enrollment email manually"
    },
    "Ajoutez ce lien à votre mail pour que les utilisateurs puissent définir leur mot de passe si besoin : https://apprenant.vigiware.com/first_connection.": {
        fr: "Ajoutez ce lien à votre mail pour que les utilisateurs puissent définir leur mot de passe si besoin : https://apprenant.vigiware.com/first_connection.",
        en: "Add this link to your email to allow users to set their password if needed : https://apprenant.vigiware.com/first_connection."
    },
    "Récapitulatif": {
        fr: "Récapitulatif",
        en: "Summary"
    },
    "Nom de la formation": {
        fr: "Nom de la formation",
        en: "Name of the campaign"
    },
    "Type de formation": {
        fr: "Type de formation",
        en: "Type of campaign"
    },
    "Durée de la formation": {
        fr: "Durée de la formation",
        en: "Campaign duration"
    },
    "Nombre d'utilisateurs": {
        fr: "Nombre d'utilisateurs",
        en: "Number of users"
    },
    "Date de début": {
        fr: "Date de début",
        en: "Start date"
    },
    "Date de fin": {
        fr: "Date de fin",
        en: "End date"
    },
    "Email envoyé par mes soins": {
        fr: "Email envoyé par mes soins",
        en: "Email sent by my own hands"
    },
    "Email automatique Vigiware": {
        fr: "Email automatique Vigiware",
        en: "Automatic Vigiware email"
    },
    "Sauvegarder": {
        fr: "Sauvegarder",
        en: "Save"
    },
    "Créer une nouvelle liste": {
        fr: "Créer une nouvelle liste",
        en: "Create a new list"
    },
    "Ou": {
        fr: "Ou",
        en: "Or"
    },
    "%weeks semaine(s)": {
        fr: "%weeks semaine(s)",
        en: "%weeks week(s)"
    },
    "%days jour(s)" : {
        fr: "%days jour(s)",
        en: "%days day(s)"
    },
}