import React, { useEffect, useState } from 'react'
import './Layout.scss'
import store, { Message, Stored } from '../../services/store'
import Toastify from 'toastify-js'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import Login from '../Login'
import Tutorial from '../Tutorial'
import LayoutMenu from './LayoutMenu'
import { useForceUpdate } from '../../utils'
import Home from '../Home'
import CampaignDetails from '../CampaignDetails'
import CampaignCreation from '../CampaignCreation'
import CampaignTemplates from '../CampaignTemplates'
import Users from '../Users'
import MyAccount from '../MyAccount'
import ResetPassword from '../ResetPassword'
import Dashboard from '../Dashboard'
import UserInformations from '../UserInformations'
import ROUTES from '../../routes'
import httpClient from '../../services/httpClient'
import User from '../../types/user'
export default () => {
  const [loggedIn, setLoggedIn] = useState(store.store.RawJWT)
  const [user, setUser] = useState<User>(undefined)
  const forceUpdate = useForceUpdate()

  store.listen(Stored.Language, forceUpdate)
  store.listen(Stored.RawJWT, setLoggedIn)
  store.listen(Message.Error, (msg) =>
    Toastify({
      text: msg,
      duration: 3000,
      stopOnFocus: true,
      backgroundColor: ' #fc7169',
    }).showToast()
  )
  store.listen(Message.Notification, (msg) =>
    Toastify({
      text: msg,
      duration: 3000,
      stopOnFocus: true,
      backgroundColor: '#4dce76',
    }).showToast()
  )
  store.listen(Message.NeedAuth, () => {
    store.update(Stored.JWT, undefined)
    store.update(Stored.RawJWT, undefined)
    store.update(Stored.RefreshToken, undefined)
  })

  return (
    <div className="Layout">
      <BrowserRouter>
        {loggedIn ? (
          <Switch>
            <Route path="/sessions/:id"></Route>
            <Route path="/">
              <LayoutMenu />
            </Route>
          </Switch>
        ) : (null)}
        <main
          className={`Layout__content${loggedIn ? '' : ' Layout__content--full'
            }`}
        >
          {!loggedIn ? (
            <Switch>
              <Route path="/reset_password">
                <ResetPassword />
              </Route>
              <Route path="/" default>
                <Login />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route path="/tutorial" default>
                <Tutorial />
              </Route>
              <Route path="/my_account" default>
                <MyAccount />
              </Route>
              <Route path="/users/:id" default>
                <UserInformations />
              </Route>
              <Route path="/users" default>
                <Users />
              </Route>
              <Route path="/campaign_templates" default>
                <CampaignTemplates />
              </Route>
              <Route path="/dashboard" default>
                <Dashboard />
              </Route>
              <Route path="/campaigns/new" default>
                <CampaignCreation editMod={false} />
              </Route>
              <Route path="/campaigns/:id/edit" default>
                <CampaignCreation editMod={true} />
              </Route>
              <Route path="/campaigns/:id" default>
                <CampaignDetails />
              </Route>
              <Route path="/" default>
                <Home />
              </Route>
            </Switch>
          )}
        </main>
      </BrowserRouter>
    </div>
  )
}
