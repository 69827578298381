export default {
    "Vous n'avez pas accès à cette interface": {
        fr: "Vous n'avez pas accès à cette interface",
        en: "You don't have access to this interface"
    },
    "Vous êtes connecté avec succès": {
        fr: "Vous êtes connecté avec succès",
        en: "You are connected successfully"
    },
    "Êtes-vous sûr de vouloir supprimer cette formation ? Cette action est irréversible.": {
        fr: "Êtes-vous sûr de vouloir supprimer cette formation ? Cette action est irréversible.",
        en: "Are you sure you want to delete this campaign? This action is irreversible."
    },
}