import React, { useState, FormEvent } from 'react'
import './Login.scss'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Stored, Message } from '../../services/store'
import { AxiosResponse } from 'axios'
import Logo from '../../../assets/images/logo.png'
import LogoWhite from '../../../assets/images/logo_white.png'
import Background from '../../../assets/images/background.png'
import IconEmail from '../../../assets/images/icons/icon_email.svg'
import IconPassword from '../../../assets/images/icons/icon_password.svg'
import IconDisplayPassword from '../../../assets/images/icons/icon_display_password.svg'
import IconHidePassword from '../../../assets/images/icons/icon_hide_password.svg'
import LoginInput from './LoginInput'
import { t } from '../../services/i18n'

export default () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  let [displayPassword, setDisplayPassword] = useState(false)

  const storeJWT = (resp: AxiosResponse) => {
    let token = resp.headers['authorization'].replace('Bearer ', '')
    let [rawJwt, jwt] = httpClient.storeCreds(token)

    if (jwt.role == 'user') {
      store.notify(Message.Error, t("Vous n'avez pas accès à cette interface"))
    } else {
      store.update(Stored.JWT, jwt)
      store.update(Stored.RawJWT, rawJwt)
      store.notify(Message.Notification, t('Vous êtes connecté avec succès'))
    }
  }

  const submitLogin = async (e: FormEvent) => {
    e.preventDefault()

    try {
      await httpClient.req(
        ROUTES.LOGIN({
          user: {
            email,
            password,
          },
        }),
        null,
        storeJWT
      )
    } catch (e) {
      store.notify(Message.Error, t('Email ou mot de passe incorrect'))
      console.warn(e)
    }
  }

  return (
    <div className="Login">
      <div className="Login__left">
        <div className="Login__leftWelcome">
          {t('Bienvenue sur la plateforme')}
        </div>
        <img src={Logo} className="Login__leftLogo" />
        <div className="Login__leftHint">
          {t('Rentrez ici vos identifiants pour accéder à la plateforme')}
        </div>
        <form onSubmit={submitLogin}>
          <LoginInput
            icon={IconEmail}
            type="text"
            value={email}
            onChange={setEmail}
            placeholder={t('Email')}
          />
          <LoginInput
            icon={IconPassword}
            type={displayPassword ? 'text' : 'password'}
            value={password}
            onChange={setPassword}
            placeholder={t('Mot de passe')}
            after={displayPassword ? IconHidePassword : IconDisplayPassword}
            afterOnClick={() => setDisplayPassword((prev) => !prev)}
          />
          <input type="submit" value={t('Connexion')} />
          <a className="Login__leftForgottenPassword" href={'/reset_password'}>
            {t('Vous avez oublié votre mot de passe ?')}
          </a>
        </form>
      </div>
      <div className="Login__right">
        <img src={Background} className="Login__rightBackground" />
        <div className="Login__rightOverlay" />
        <img src={LogoWhite} className="Login__rightLogo" />
        <div className="Login__rightText">
          {t('Sensibilisez vos équipes, suivez leur progression jusqu\'à la certification.')}
        </div>
      </div>
    </div>
  )
}
