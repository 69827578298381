export default {
    "Accueil": {
        fr: "Accueil",
        en: "Home"
    },
    "Modèles de formation": {
        fr: "Modèles de formation",
        en: "Training models"
    },
    "Utilisateurs": {
        fr: "Utilisateurs",
        en: "Users"
    },
    "Mon compte": {
        fr: "Mon compte",
        en: "My account"
    },
    "Déconnexion": {
        fr: "Déconnexion",
        en: "Logout"
    },
    "Régularité des apprenants : ": {
        fr: "Régularité des apprenants : ",
        en: "Student regularity : "
    },
    "Orageux": {
        fr: "Orageux",
        en: "Stormy"
    },
    "Pluvieux": {
        fr: "Pluvieux",
        en: "Rainy"
    },
    "Variable": {
        fr: "Variable",
        en: "Variable"
    },
    "Radieux": {
        fr: "Radieux",
        en: "Sunny"
    },
    "Formations en cours": {
        fr: "Formations en cours",
        en: "Ongoing campaigns"
    },
    "Taux moyen de participation": {
        fr: "Taux moyen de participation",
        en: "Average participation rate"
    },
    "Inscriptions": {
        fr: "Inscriptions",
        en: "Enrollments"
    },
    "Apprenants en cours": {
        fr: "Apprenants en cours",
        en: "Active users"
    },
    "Formations en cours (%count)": {
        fr: "Formations en cours (%count)",
        en: "Ongoing campaigns (%count)"
    },
    "Aucune campagne en cours": {
        fr: "Aucune campagne en cours",
        en: "No ongoing campaign"
    },
    "Créer une nouvelle formation": {
        fr: "Créer une nouvelle formation",
        en: "Create a new campaign"
    },
    "jours restants": {
        fr: "jours restants",
        en: "days remaining"
    },
    "Semaine du %start au %end": {
        fr: "Semaine du %start au %end",
        en: "Week %start to %end"
    },
    "inscrits": {
        fr: "inscrits",
        en: "assigned"
    },
    "Taux de participation": {
        fr: "Taux de participation",
        en: "Participation rate"
    },
    "apprenants certifiés": {
        fr: "apprenants certifiés",
        en: "certified users"
    },
    "Apprenants certifiés": {
        fr: "Apprenants certifiés",
        en: "Certified users"
    },
    "Taux d'activité": {
        fr: "Taux d'activité",
        en: "Activity rate"
    },
    "(7 derniers jours)": {
        fr: "(7 derniers jours)",
        en: "(7 last days)"
    },
    "du %start au %end": {
        fr: "du %start au %end",
        en: "from %start to %end"
    },
    "Formations à venir (%count)": {
        fr: "Formations à venir (%count)",
        en: "Upcoming campaigns (%count)"
    },
    "Formations passées (%count)": {
        fr: "Formations passées (%count)",
        en: "Past campaigns (%count)"
    },
    "Modifier les formations et contenus": {
        fr: "Modifier les formations et contenus",
        en: "Edit trainings and contents"
    },
    "Télécharger une nouvelle liste": {
        fr: "Télécharger une nouvelle liste",
        en: "Download a new list"
    },
    "Séléctionner la liste à afficher": {
        fr: "Séléctionner la liste à afficher",
        en: "Select a list to be displayed"
    },
    "Tous les utilisateurs": {
        fr: "Tous les utilisateurs",
        en: "All users"
    },
    "Créer un utilisateur": {
        fr: "Créer un utilisateur",
        en: "Create a user"
    },
    "Récapitulatif (%count)": {
        fr: "Récapitulatif (%count)",
        en: "Summary (%count)"
    },
    "Correspondants": {
        fr: "Correspondants",
        en: "Correspondants"
    },
    "Apprenants": {
        fr: "Apprenants",
        en: "Users"
    },
    "Profil": {
        fr: "Profil",
        en: "Profile"
    },
    "Langue": {
        fr: "Langue",
        en: "Language"
    },
    "Mot de passe actuel (nécessaire pour changer de mot de passe)": {
        fr: "Mot de passe actuel (nécessaire pour changer de mot de passe)",
        en: "Current password (required to change password)"
    },
    "Nouveau mot de passe": {
        fr: "Nouveau mot de passe",
        en: "New password"
    },
    "Confirmation nouveau mot de passe": {
        fr: "Confirmation nouveau mot de passe",
        en: "New password confirmation"
    },
    "Annuler": {
        fr: "Annuler",
        en: "Cancel"
    },
    "Enregistrer": {
        fr: "Enregistrer",
        en: "Save"
    },
    "Rechercher": {
        fr: "Rechercher",
        en: "Search"
    },
    "Tableau de bord": {
        fr: "Tableau de bord",
        en: "Dashboard"
    },
}