import React, { ComponentClass } from 'react'
import './SearchBar.scss'

interface Props {
  className?: string
  icon: ComponentClass
  after?: ComponentClass
  afterOnClick?: () => void
  type?: string
  value: string
  onChange: (v: string) => void
  placeholder?: string
}

export default (props: Props) => {
  let Icon = props.icon

  return (
    <div className='search-form'>
      <div className="search-icon">
        <Icon />
      </div>
      <input
        className='search-input'
        type={props.type || 'text'}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
      />
    </div>
  )
}