import React, { useState, useEffect } from 'react'
import './CampaignTemplates.scss'
import CampaignTemplate from '../../types/campaign_template'
import httpClient from '../../services/httpClient'
import { t } from '../../services/i18n'
import store, { Message } from '../../services/store'
import ROUTES from '../../routes'
import Button from '../../components/Button'
import { useHistory } from 'react-router-dom'

export default () => {
  const [templates, setTemplates] = useState<CampaignTemplate[]>([])
  const history = useHistory()

  useEffect(() => {
    fetchTemplates()
  }, [])

  const fetchTemplates = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_CAMPAIGN_TEMPLATES())

      setTemplates(res)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les formations'))
      console.warn(e)
    }
  }

  const contentsCountByType = (template: CampaignTemplate) => {
    let count: any = {}

    template.contents.forEach((c) => {
      switch (c.contentType) {
        case 'video':
          count[t('Vidéos')] = (count[t('Vidéos')] || 0) + 1
          break
        case 'one_answer_question':
          count[t('Quizz')] = (count[t('Quizz')] || 0) + 1
          break
        case 'multiple_answer_question':
          count[t('Quizz')] = (count[t('Quizz')] || 0) + 1
          break
        case 'list_to_order':
          count[t('Liste à ordonner')] = (count[t('Liste à ordonner')] || 0) + 1
          break
      }
    })
    return count
  }

  return (
    <div className="CampaignTemplates">
      {['admin', 'superadmin'].includes(store.store.JWT.role) && (
        <a
          className="CampaignTemplates__adminLink"
          href="https://admin.vigiware.com"
          target="_blank"
        >
          <Button
            title={t('Modifier les formations et contenus')}
            onClick={undefined}
            secondaryColor
          />
        </a>
      )}
      <div className="CampaignTemplates__body">
        {templates.map((ct) => {
          const contentCounts = contentsCountByType(ct)

          return (
            <div className="CampaignTemplates__template" key={ct.id}>
              <img
                className="CampaignTemplates__templateImage"
                src={ct.imageUrl}
              />
              <div className="CampaignTemplates__templateBody" key={ct.id}>
                <div className="CampaignTemplates__templateTitle">
                  {ct.title}
                </div>
                <div className="CampaignTemplates__templateSubtitle">
                  {ct.subtitle}
                </div>
                <div className="CampaignTemplates__templateDescription">
                  {ct.description}
                </div>
                <div className="CampaignTemplates__templateSeparator" />
                <div className="CampaignTemplates__templateFooter">
                  <div>
                    <div className="CampaignTemplates__templateFooterDuration">
                      {t('Durée totale des sessions : ')}
                      <span>
                        {t('%minutes minutes', {
                          minutes: Math.floor(ct.totalDuration / 60),
                        })}
                      </span>
                    </div>
                    {Object.keys(contentCounts).length > 0 && (
                      <div className="CampaignTemplates__templateFooterContents">
                        {Object.keys(contentCounts)
                          .map<React.ReactNode>((k) => (
                            <div key={k}>
                              {k}: <span>{contentCounts[k]}</span>
                            </div>
                          ))
                          .reduce((prev, curr) => [
                            prev,
                            <div className="CampaignTemplates__templateFooterContentsSeparator">
                              |
                            </div>,
                            curr,
                          ])}
                      </div>
                    )}
                  </div>
                  <Button
                    title={t('Créer une formation')}
                    secondaryColor
                    className="CampaignTemplates__templateFooterContents"
                    onClick={() =>
                      history.push(`/campaigns/new?templateId=${ct.id}`)
                    }
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
