import React, { useState, FormEvent } from 'react';
import './ResetPassword.scss';
import httpClient from '../../services/httpClient';
import ROUTES from '../../routes';
import store, { Message } from '../../services/store';
import Logo from '../../../assets/images/logo.png';
import LogoWhite from '../../../assets/images/logo_white.png';
import Background from '../../../assets/images/background.png';
import IconEmail from '../../../assets/images/icons/icon_email.svg';
import IconPassword from '../../../assets/images/icons/icon_password.svg';
import LoginInput from '../Login/LoginInput';
import { t } from '../../services/i18n';
import { useHistory, useParams } from 'react-router-dom';

export default () => {
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [email, setEmail] = useState('');
  const { token } = useParams();
  const history = useHistory();

  const askPasswordReset = async (e: FormEvent) => {
    e.preventDefault();

    try {
      await httpClient.req(
        ROUTES.ASK_PASSWORD_RESET({
          email,
        })
      );

      store.notify(
        Message.Notification,
        t(
          "Si l'email est correct, vous devriez recevoir un mail sous peu"
        )
      );
    } catch (e) {
      store.notify(
        Message.Error,
        t(
          'Impossible de demander la réinitialisation du mot de passe'
        )
      );
      console.warn(e);
    }
  };

  const resetPassword = async (e: FormEvent) => {
    e.preventDefault();
    if (password !== verifyPassword) {
      store.notify(
        Message.Error,
        t('Les mots de passe ne correspondent pas')
      );
      return;
    }

    try {
      await httpClient.req(
        ROUTES.RESET_PASSWORD({
          password,
          token,
        })
      );

      store.notify(
        Message.Notification,
        t('Votre mot de passe a été changé avec succès')
      );
      history.push('/');
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de changer votre mot de passe')
      );
      console.warn(e);
    }
  };

  return (
    <div className="ResetPassword">
      <div className="ResetPassword__left">
        <div className="ResetPassword__leftWelcome">
          {t('Réinitialisation du mot de passe')}
        </div>
        <img src={Logo} className="ResetPassword__leftLogo" />
        <div className="ResetPassword__leftHint">
          {token
            ? t('Rentrez ici votre nouveau mot de passe')
            : t(
                'Rentrez ici votre email afin de pouvoir réinitaliser votre mot de passe'
              )}
        </div>
        <form onSubmit={token ? resetPassword : askPasswordReset}>
          {token ? (
            <div>
              <LoginInput
                icon={IconPassword}
                type="password"
                value={password}
                onChange={setPassword}
                placeholder={t('Mot de passe')}
              />
              <LoginInput
                icon={IconPassword}
                type="password"
                value={verifyPassword}
                onChange={setVerifyPassword}
                placeholder={t('Vérifier le mot de passe')}
              />
            </div>
          ) : (
            <LoginInput
              icon={IconEmail}
              type="text"
              value={email}
              onChange={setEmail}
              placeholder={t('Email')}
            />
          )}
          <input type="submit" value={t('Réinitialiser')} />
          <a
            className="ResetPassword__leftForgottenPassword"
            href="/login"
          >
            {t('Se connecter')}
          </a>
        </form>
      </div>
      <div className="ResetPassword__right">
        <img
          src={Background}
          className="ResetPassword__rightBackground"
        />
        <div className="ResetPassword__rightOverlay" />
        <img src={LogoWhite} className="ResetPassword__rightLogo" />
      </div>
    </div>
  );
};
