export default {
    "Réinitialisation du mot de passe": {
        fr: "Réinitialisation du mot de passe",
        en: "Password reset"
    },
    "Rentrez ici votre email afin de pouvoir réinitaliser votre mot de passe": {
        fr: "Rentrez ici votre email afin de pouvoir réinitaliser votre mot de passe",
        en: "Enter your email to reset your password"
    },
    "Rentrez ici votre nouveau mot de passe": {
        fr: "Rentrez ici votre nouveau mot de passe",
        en: "Enter your new password"
    },
    "Réinitialiser": {
        fr: "Réinitialiser",
        en: "Reset"
    },
    "Se connecter": {
        fr: "Se connecter",
        en: "Login"
    }
}