import React, { useState, Fragment, useEffect } from 'react';
import './MyAccount.scss';
import Button from '../../components/Button';
import InputWithLabel from '../../components/InputWithLabel';
import User from '../../types/user';
import Loader from 'react-loader-spinner';
import httpClient from '../../services/httpClient';
import axios from 'axios';
import ROUTES from '../../routes';
import store, { Message, Stored } from '../../services/store';
import Language from '../../types/language';
import PicturePlaceholder from '../../../assets/images/profile_picture_placeholder.png';
import { useHistory } from 'react-router-dom';
import i18n, { Lng, setLng, t } from '../../services/i18n';

export default () => {
  const [user, setUser] = useState<User>(undefined);
  const [languages, setLanguages] = useState<Language[]>(undefined);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetchUser();
    fetchLanguages();
  }, []);

  useEffect(() => {
    setLoading(!(user && languages));
  }, [user, languages]);

  const fetchUser = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_ME());
      setUser(res);
    } catch (e) {
      store.notify(
        Message.Error,
        'Impossible de récupérer le profil'
      );
      console.warn(e);
    }
  };

  const testGoogleFetch = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_UPLOADS());
      let data = {};

      // let formData = new FormData();
      let i = 1;
      for await (let re of res) {
        let url = re.url;

        let blob = await axios.get(url, {
          responseType: 'blob',
        });
        let file = new File(
          [blob.data],
          url.substring(url.lastIndexOf('/') + 1),
          {
            type: blob.data.type,
          }
        );

        data = {
          file: file,
          id: re.id,
          tag: re.tag,
        };
        i++;
      }
    } catch (e) {
      store.notify(
        Message.Error,
        'Impossible de réorganiser les images'
      );
      console.warn(e);
    }
  };

  const fetchUploads = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_UPLOADS());
      let data = {};

      // let formData = new FormData();
      let i = 1;
      for await (let re of res) {
        let url = re.url;

        let blob = await axios.get(url, {
          responseType: 'blob',
        });
        let file = new File(
          [blob.data],
          url.substring(url.lastIndexOf('/') + 1),
          {
            type: blob.data.type,
          }
        );

        data = {
          file: file,
          id: re.id,
          tag: re.tag,
        };
        let res2 = await httpClient.req(ROUTES.RELOCATE_FILES(data));
        i++;
      }
    } catch (e) {
      store.notify(
        Message.Error,
        'Impossible de réorganiser les images'
      );
      console.warn(e);
    }
  };

  const updateLng = () => {
    let lng: Lng = Lng.en;
    let jwt = JSON.parse(localStorage.getItem('state'));

    localStorage.setItem('state', JSON.stringify(jwt));
    if (user.languageId == '8e35cec7-c6fb-480d-a958-d1224e63ac30')
      lng = Lng.fr;
    else if (
      user.languageId == '35e3c097-506f-4654-b0e5-d703d0750cde'
    )
      lng = Lng.en;
    jwt.JWT.language = lng;
    jwt.Language = lng;
    store.store.JWT.language = lng;
    store.store.Language = lng;
    setLng(lng);
  };

  const updateUser = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.UPDATE_ME({ user: user })
      );

      setUser(res);
      httpClient.updateLocale(
        languages.find((l) => l.id == res.languageId).code
      );
      history.push('/');
      store.notify(Message.RefreshMenu);
      updateLng();
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de mettre à jour le profil')
      );
      console.warn(e);
    }
  };

  const fetchLanguages = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_LANGUAGES());

      JSON.parse(localStorage.getItem('state'));
      setLanguages(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les langues')
      );
      console.warn(e);
    }
  };

  const updateImage = async (file: File) => {
    try {
      let res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }));
      setUser({ ...user, imageUrl: res.imageUrl });
    } catch (e) {
      store.notify(
        Message.Error,
        t("Impossible de mettre à jour l'image")
      );
      console.warn(e);
    }
  };

  return (
    <div className="MyAccount">
      {loading ? (
        <Loader
          type="ThreeDots"
          color="#62a5e2"
          height={100}
          width={100}
        />
      ) : (
        <Fragment>
          <div className="MyAccount__header">{t('Profil')}</div>
          <div className="MyAccount__content">
            <div className="MyAccount__contentLeft">
              <div className="MyAccount__contentLeftPicture">
                <label>
                  <div className="MyAccount__contentLeftPictureHover">
                    <i>cloud_upload</i>
                  </div>
                  <img
                    src={
                      user.imageUrl
                        ? user.imageUrl
                        : PicturePlaceholder
                    }
                  />
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => updateImage(e.target.files[0])}
                  />
                </label>
              </div>
              <div className="MyAccount__contentLeftName">
                {user.firstName} {user.lastName}
              </div>
            </div>
            <div className="MyAccount__contentRight">
              <InputWithLabel
                value={
                  languages.find((l) => l.id == user.languageId)
                    ?.id || 'fr'
                }
                label={t('Langue')}
                onChange={(v) => setUser({ ...user, languageId: v })}
                selectOptions={languages}
                selectLabelKey={'name'}
                selectValueKey={'id'}
              />
              <InputWithLabel
                value={user.currentPassword}
                label={t(
                  'Mot de passe actuel (nécessaire pour changer de mot de passe)'
                )}
                type="password"
                onChange={(v) =>
                  setUser({ ...user, currentPassword: v })
                }
              />
              <InputWithLabel
                value={user.password}
                label={t('Nouveau mot de passe')}
                type="password"
                onChange={(v) => setUser({ ...user, password: v })}
              />
              <InputWithLabel
                value={user.passwordConfirmation}
                label={t('Confirmation nouveau mot de passe')}
                type="password"
                onChange={(v) =>
                  setUser({ ...user, passwordConfirmation: v })
                }
              />
            </div>
          </div>
          <div className="MyAccount__buttons">
            <Button
              title={t('Déconnexion')}
              dangerColor
              onClick={() => store.notify(Message.NeedAuth)}
            />
            <Button
              title={t('Annuler')}
              onClick={() => history.push('/')}
            ></Button>
            <Button
              title={t('Enregistrer')}
              secondaryColor
              onClick={updateUser}
            ></Button>
            {(user.id == 'cd75f974-4fbb-4d0f-a966-6be316dffec1' ||
              user.id == 'a58364c5-5e2f-4932-b0ee-aaa13345fc17') && (
                <div>
                  <Button
                    title={t('Test du fetch ☢️')}
                    dangerColor
                    onClick={testGoogleFetch}
                  ></Button>
                  <Button
                    title={t('Relocaliser les images ⚠️')}
                    dangerColor
                    onClick={fetchUploads}
                  ></Button>
                </div>
              )}
          </div>
        </Fragment>
      )}
    </div>
  );
};
