import React, { useEffect, useState, Fragment } from 'react';
import dayjs from 'dayjs';
import { t } from '../../services/i18n';
import './Home.scss';
import httpClient from '../../services/httpClient';
import ROUTES from '../../routes';
import store, { Message } from '../../services/store';
import Campaign from '../../types/campaign';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import User from '../../types/user';
import Accordion from '../../components/Accordion';
import StormBackground from '../../../assets/images/storm.png';
import RainBackground from '../../../assets/images/rain.png';
import CloudBackground from '../../../assets/images/cloud.png';
import SunBackground from '../../../assets/images/sun.png';
import StormIcon from '../../../assets/images/storm_icon.png';
import RainIcon from '../../../assets/images/rain_icon.png';
import CloudIcon from '../../../assets/images/cloud_icon.png';
import SunIcon from '../../../assets/images/sun_icon.png';

const Home = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [archivedCampaigns, setArchivedCampaigns] = useState<
    Campaign[]
  >([]);
  const [campaignsStats, setCampaignsStats] = useState<{
    campaignsCount: number;
    usersCount: number;
    activeUsersCount: number;
    assignedUsers: number;
    lastSevenDaysActiveUsersCount: number;
  }>(undefined);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    // fetchCampaigns();
    fetchAllCampaigns();
    fetchAllArchivedCampaigns();
    fetchUsers();
    fetchCampaignsStats();
  }, []);

  useEffect(() => {
    setLoading(!campaigns || !campaignsStats);
  }, [campaigns, campaignsStats]);

  const fetchCampaigns = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_CAMPAIGNS({ all: false })
      );

      if (campaigns.length > 0) {
        setCampaigns(res);
      }
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les formations')
      );
      console.warn(e);
    }
  };

  const fetchAllCampaigns = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_CAMPAIGNS({ all: true })
      );
      setCampaigns(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les formations')
      );
      console.warn(e);
    }
  };

  const fetchAllArchivedCampaigns = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_ARCHIVED_CAMPAIGNS({ all: true })
      );
      setArchivedCampaigns(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les formations archivées')
      );
      console.warn(e);
    }
  };

  const fetchUsers = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_USERS({ license_id: store.store.JWT.license_id })
      );

      setUsers(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les utilisateurs')
      );
      console.warn(e);
    }
  };

  const fetchCampaignsStats = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_CAMPAIGNS_STATS());

      setCampaignsStats(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les statistiques des formations')
      );
      console.warn(e);
    }
  };

  const archiveCampaign = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    campaign: Campaign
  ) => {
    e.stopPropagation();
    if (
      confirm(
        campaign.archived
          ? t('Êtes-vous sûr de vouloir restaurer cette formation ?')
          : t('Êtes-vous sûr de vouloir archiver cette formation ?')
      )
    ) {
      try {
        campaign.archived = !campaign.archived;
        await httpClient.req(
          ROUTES.UPDATE_CAMPAIGN({
            campaign,
          })
        );
        if (campaign.archived) {
          setArchivedCampaigns([...archivedCampaigns, campaign]);
          setCampaigns(campaigns.filter((c) => c.id != campaign.id));
        } else {
          setCampaigns([...campaigns, campaign]);
          setArchivedCampaigns(
            archivedCampaigns.filter((c) => c.id != campaign.id)
          );
        }
      } catch (e) {
        store.notify(
          Message.Error,
          t("Impossible d'archiver cette formation")
        );
        console.warn(e);
      }
    }
  };

  const deleteCampaign = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    campaign: Campaign
  ) => {
    e.stopPropagation();
    if (
      confirm(
        t(
          'Êtes-vous sûr de vouloir supprimer cette formation ? Cette action est irréversible.'
        )
      )
    ) {
      try {
        await httpClient.req(
          ROUTES.DELETE_CAMPAIGN({ id: campaign.id })
        );

        setCampaigns(campaigns.filter((c) => c.id != campaign.id));
      } catch (e) {
        store.notify(
          Message.Error,
          t('Impossible de supprimer la formation')
        );
        console.warn(e);
      }
    }
  };

  const campaignCard = (c: Campaign) => {
    const manager = users.find((u) => u.id == c.userId);
    let mailer = "mailto:"
    c.users.forEach((user, index) => {
      if (index !== c.users.length - 1)
        mailer = mailer + user.email + ";"
      else
        mailer = mailer + user.email
    })

    return (
      <div
        className="Home__campaignsCard"
        key={c.id}
        onClick={() => history.push(`/campaigns/${c.id}`)}
      >
        <div className="Home__campaignsCardButtons">
          <a
            href={`/campaigns/${c.id}/edit`}
            className="Home__campaignsCardButtonsEntry Home__campaignsCardButtonsEntry--edit"
          >
            <i>edit</i>
          </a>
          <a
            href={mailer}
            className="Home__campaignsCardButtonsEntry Home__campaignsCardButtonsEntry--edit"
          >
            <i>mail</i>
          </a>
          <i
            onClick={(e) => archiveCampaign(e, c)}
            className="Home__campaignsCardButtonsEntry Home__campaignsCardButtonsEntry--danger"
          >
            delete
          </i>
          {/* <i className="Home__campaignsCardButtonsEntry">more_horiz</i> */}
        </div>
        <div className="Home__campaignsCardHeader">
          <div className="Home__campaignsCardTitle">{c.title}</div>
          {manager && (
            <div className="Home__campaignsCardSubtitle">
              {manager.firstName} {manager.lastName}
            </div>
          )}
          <div className="Home__campaignsCardIndicators">
            <div className="Home__campaignsCardIndicatorsEntry">
              <i className="Home__campaignsCardIndicatorsEntryIcon">
                hourglass_full
              </i>
              <div className="Home__campaignsCardIndicatorsEntryLabel">
                {dayjs(c.endDate).diff(dayjs(), 'day')}{' '}
                {t('jours restants')}
              </div>
            </div>
            <div className="Home__campaignsCardIndicatorsEntry">
              <i className="Home__campaignsCardIndicatorsEntryIcon">
                people
              </i>
              <div className="Home__campaignsCardIndicatorsEntryLabel">
                {c.users.length} {t('inscrits')}
              </div>
            </div>
          </div>
        </div>
        <div className="Home__campaignsCardSeparator" />
        <div className="Home__campaignsCardStats">
          <div className="Home__campaignsCardStatsEntry">
            <div className="Home__campaignsCardStatsEntryPercentage">
              <div className="Home__campaignsCardStatsEntryPercentageBody Home__campaignsCardStatsEntryPercentageBody--accent">
                <div>
                  {Math.floor(
                    (c.stats.activeUsersCount / c.stats.usersCount) *
                    100
                  )}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className="Home__campaignsCardStatsEntryDetails">
              <div className="Home__campaignsCardStatsEntryDetailsTitle">
                {t('Taux de participation')}
              </div>
              <div className="Home__campaignsCardStatsEntryDetailsBody">
                <div className="Home__campaignsCardStatsEntryDetailsBodyProgress">
                  <div className="Home__campaignsCardStatsEntryDetailsBodyProgressLabel">
                    {t('Apprenants en cours')}
                  </div>
                  <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBar">
                    <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBarBody">
                      <div
                        className="Home__campaignsCardStatsEntryDetailsBodyProgressBarBodyFill"
                        style={{
                          width: `${Math.floor(
                            (c.stats.activeUsersCount /
                              c.stats.usersCount) *
                            100
                          )}%`,
                        }}
                      />
                    </div>
                    <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBarLabel">
                      {Math.floor(
                        (c.stats.activeUsersCount /
                          c.stats.usersCount) *
                        100
                      )}
                      %
                    </div>
                  </div>
                </div>
                <div className="Home__campaignsCardStatsEntryDetailsBodyProgress">
                  <div className="Home__campaignsCardStatsEntryDetailsBodyProgressLabel">
                    {t('Apprenants certifiés')}
                  </div>
                  <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBar">
                    <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBarBody">
                      <div
                        className="Home__campaignsCardStatsEntryDetailsBodyProgressBarBodyFill"
                        style={{
                          width: `${Math.floor(
                            (c.stats.certificationValidatedCount /
                              c.stats.usersCount) *
                            100
                          )}%`,
                        }}
                      />
                    </div>
                    <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBarLabel">
                      {Math.floor(
                        (c.stats.certificationValidatedCount /
                          c.stats.usersCount) *
                        100
                      )}
                      %
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Home__campaignsCardStatsEntry">
            <div className="Home__campaignsCardStatsEntryPercentage">
              <div className="Home__campaignsCardStatsEntryPercentageBody">
                <div>
                  {Math.floor(
                    (c.stats.lastSevenDaysActiveUsersCount /
                      c.stats.usersCount) *
                    100
                  )}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className="Home__campaignsCardStatsEntryDetails">
              <div className="Home__campaignsCardStatsEntryDetailsTitle">
                {t("Taux d'activité")}{' '}
                <span>{t('(7 derniers jours)')}</span>
              </div>
              <div className="Home__campaignsCardStatsEntryDetailsBody">
                <div className="Home__campaignsCardStatsEntryDetailsBodySubtitle">
                  {t('du %start au %end', {
                    start: dayjs().subtract(7, 'day').format('L'),
                    end: dayjs().format('L'),
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const archivedCampaignCard = (c: Campaign) => {
    const manager = users.find((u) => u.id == c.userId);

    return (
      <div
        className="Home__campaignsCard"
        key={c.id}
        onClick={() => history.push(`/campaigns/${c.id}`)}
      >
        <div className="Home__campaignsCardButtons">
          <a
            onClick={(e) => archiveCampaign(e, c)}
            className="Home__campaignsCardButtonsEntry Home__campaignsCardButtonsEntry--edit"
          >
            <i>restore</i>
          </a>
          <i
            onClick={(e) => deleteCampaign(e, c)}
            className="Home__campaignsCardButtonsEntry Home__campaignsCardButtonsEntry--danger"
          >
            delete
          </i>
          {/* <i className="Home__campaignsCardButtonsEntry">more_horiz</i> */}
        </div>
        <div className="Home__campaignsCardHeader">
          <div className="Home__campaignsCardTitle">{c.title}</div>
          {manager && (
            <div className="Home__campaignsCardSubtitle">
              {manager.firstName} {manager.lastName}
            </div>
          )}
          <div className="Home__campaignsCardIndicators">
            <div className="Home__campaignsCardIndicatorsEntry">
              <i className="Home__campaignsCardIndicatorsEntryIcon">
                hourglass_full
              </i>
              <div className="Home__campaignsCardIndicatorsEntryLabel">
                {dayjs(c.endDate).diff(dayjs(), 'day')}{' '}
                {t('jours restants')}
              </div>
            </div>
            <div className="Home__campaignsCardIndicatorsEntry">
              <i className="Home__campaignsCardIndicatorsEntryIcon">
                people
              </i>
              <div className="Home__campaignsCardIndicatorsEntryLabel">
                {c.users.length} {t('inscrits')}
              </div>
            </div>
          </div>
        </div>
        <div className="Home__campaignsCardSeparator" />
        <div className="Home__campaignsCardStats">
          <div className="Home__campaignsCardStatsEntry">
            <div className="Home__campaignsCardStatsEntryPercentage">
              <div className="Home__campaignsCardStatsEntryPercentageBody Home__campaignsCardStatsEntryPercentageBody--accent">
                <div>
                  {Math.floor(
                    (c.stats.activeUsersCount / c.stats.usersCount) *
                    100
                  )}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className="Home__campaignsCardStatsEntryDetails">
              <div className="Home__campaignsCardStatsEntryDetailsTitle">
                {t('Taux de participation')}
              </div>
              <div className="Home__campaignsCardStatsEntryDetailsBody">
                <div className="Home__campaignsCardStatsEntryDetailsBodyProgress">
                  <div className="Home__campaignsCardStatsEntryDetailsBodyProgressLabel">
                    {t('Apprenants en cours')}
                  </div>
                  <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBar">
                    <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBarBody">
                      <div
                        className="Home__campaignsCardStatsEntryDetailsBodyProgressBarBodyFill"
                        style={{
                          width: `${Math.floor(
                            (c.stats.activeUsersCount /
                              c.stats.usersCount) *
                            100
                          )}%`,
                        }}
                      />
                    </div>
                    <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBarLabel">
                      {Math.floor(
                        (c.stats.activeUsersCount /
                          c.stats.usersCount) *
                        100
                      )}
                      %
                    </div>
                  </div>
                </div>
                <div className="Home__campaignsCardStatsEntryDetailsBodyProgress">
                  <div className="Home__campaignsCardStatsEntryDetailsBodyProgressLabel">
                    {t('Apprenants certifiés')}
                  </div>
                  <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBar">
                    <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBarBody">
                      <div
                        className="Home__campaignsCardStatsEntryDetailsBodyProgressBarBodyFill"
                        style={{
                          width: `${Math.floor(
                            (c.stats.certificationValidatedCount /
                              c.stats.usersCount) *
                            100
                          )}%`,
                        }}
                      />
                    </div>
                    <div className="Home__campaignsCardStatsEntryDetailsBodyProgressBarLabel">
                      {Math.floor(
                        (c.stats.certificationValidatedCount /
                          c.stats.usersCount) *
                        100
                      )}
                      %
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Home__campaignsCardStatsEntry">
            <div className="Home__campaignsCardStatsEntryPercentage">
              <div className="Home__campaignsCardStatsEntryPercentageBody">
                <div>
                  {Math.floor(
                    (c.stats.lastSevenDaysActiveUsersCount /
                      c.stats.usersCount) *
                    100
                  )}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className="Home__campaignsCardStatsEntryDetails">
              <div className="Home__campaignsCardStatsEntryDetailsTitle">
                {t("Taux d'activité")}{' '}
                <span>{t('(7 derniers jours)')}</span>
              </div>
              <div className="Home__campaignsCardStatsEntryDetailsBody">
                <div className="Home__campaignsCardStatsEntryDetailsBodySubtitle">
                  {t('du %start au %end', {
                    start: dayjs().subtract(7, 'day').format('L'),
                    end: dayjs().format('L'),
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const backgroundForMeteo = () => {
    const involvement =
      (campaignsStats.lastSevenDaysActiveUsersCount * 100) /
      campaignsStats.assignedUsers;

    if (involvement < 25) {
      return StormBackground;
    } else if (involvement >= 25 && involvement < 50) {
      return RainBackground;
    } else if (involvement >= 50 && involvement < 75) {
      return CloudBackground;
    } else {
      return SunBackground;
    }
  };

  const iconForMeteo = () => {
    const involvement =
      (campaignsStats.lastSevenDaysActiveUsersCount * 100) /
      campaignsStats.assignedUsers;

    if (involvement < 25) {
      return StormIcon;
    } else if (involvement >= 25 && involvement < 50) {
      return RainIcon;
    } else if (involvement >= 50 && involvement < 75) {
      return CloudIcon;
    } else {
      return SunIcon;
    }
  };

  const nameForMeteo = () => {
    const involvement =
      (campaignsStats.lastSevenDaysActiveUsersCount * 100) /
      campaignsStats.assignedUsers;

    if (involvement < 25) {
      return t('Orageux');
    } else if (involvement >= 25 && involvement < 50) {
      return t('Pluvieux');
    } else if (involvement >= 50 && involvement < 75) {
      return t('Variable');
    } else {
      return t('Radieux');
    }
  };

  const statCard = (child: JSX.Element) => {
    const campaignsCount = campaigns.filter(
      (c) =>
        dayjs(c.endDate).isAfter(dayjs()) &&
        dayjs(c.startDate).isBefore(dayjs())
    ).length;

    return (
      <div
        className={`Home__statsCard${campaignsCount == 0 ? ' Home__statsCard--disabled' : ''
          }`}
      >
        {campaignsCount > 0 ? (
          child
        ) : (
          <div className="">{t('Aucune campagne en cours')}</div>
        )}
      </div>
    );
  };

  return (
    <div className="Home">
      {loading ? (
        <Loader
          type="ThreeDots"
          color="#62a5e2"
          height={100}
          width={100}
        />
      ) : (
        <Fragment>
          <div className="Home__header">
            <div
              className="CampaignDetails__headerMeteo"
              style={{ background: `url(${backgroundForMeteo()})` }}
            >
              <div>
                <div className="CampaignDetails__headerRegularity">
                  {t('Régularité des apprenants : ')}{' '}
                  <span>{nameForMeteo()}</span>
                  <span>
                    {' ('}
                    {Math.round(
                      ((campaignsStats.lastSevenDaysActiveUsersCount *
                        100) /
                        campaignsStats.assignedUsers) *
                      100
                    ) / 100}
                    {'%)'}
                  </span>
                </div>
                <div className="CampaignDetails__headerDate">
                  {t('Semaine du %start au %end', {
                    start: dayjs().subtract(7, 'day').format('L'),
                    end: dayjs().format('L'),
                  })}
                </div>
              </div>
              <div>
                <div className="CampaignDetails__headerMeteoIconBackground" />
                <img
                  src={iconForMeteo()}
                  className="CampaignDetails__headerMeteoIcon"
                />
              </div>
            </div>
          </div>
          <div className="Home__stats">
            <div className="Home__statsCard Home__statsCard--main">
              <div className="Home__statsCardTitle">
                {
                  campaigns.filter(
                    (c) =>
                      dayjs(c.endDate).isAfter(dayjs()) &&
                      dayjs(c.startDate).isBefore(dayjs())
                  ).length
                }
              </div>
              <div>{t('Formations en cours')}</div>
            </div>
            {statCard(
              <Fragment>
                <div className="Home__statsCardTitle">
                  {Math.round(
                    (campaignsStats.activeUsersCount /
                      campaignsStats.usersCount) *
                    100
                  )}
                  <span>%</span>
                </div>
                <div>{t('Taux moyen de participation')}</div>
              </Fragment>
            )}
            {statCard(
              <Fragment>
                <div className="Home__statsCardTitle">
                  {campaignsStats.usersCount}
                </div>
                <div>{t('Inscriptions')}</div>
              </Fragment>
            )}
            {statCard(
              <Fragment>
                <div className="Home__statsCardTitle">
                  {campaignsStats.activeUsersCount}
                </div>
                <div>{t('Apprenants en cours')}</div>
              </Fragment>
            )}
          </div>
          <Accordion
            openByDefault
            titleRenderer={() => (
              <div className="Home__separator">
                {t('Formations en cours (%count)', {
                  count: campaigns.filter(
                    (c) =>
                      dayjs(c.endDate).isAfter(dayjs()) &&
                      dayjs(c.startDate).isBefore(dayjs())
                  ).length,
                })}
                <i>expand_more</i>
                <div className="Home__separatorBar" />
              </div>
            )}
          >
            <div className="Home__campaigns">
              <div
                className="Home__campaignsAdd Home__campaignsCard"
                onClick={() => history.push('/campaigns/new')}
              >
                <div className="Home__campaignsAddIcon">
                  <i>add</i>
                </div>
                <div className="Home__campaignsAddTitle">
                  {t('Créer une nouvelle formation')}
                </div>
              </div>
              {campaigns
                .filter(
                  (c) =>
                    dayjs(c.endDate).isAfter(dayjs()) &&
                    dayjs(c.startDate).isBefore(dayjs())
                )
                .map((c) => campaignCard(c))}
            </div>
          </Accordion>
          <Accordion
            openByDefault={
              campaigns.filter((c) =>
                dayjs(c.startDate).isAfter(dayjs())
              ).length > 0
            }
            titleRenderer={() => (
              <div className="Home__separator">
                {t('Formations à venir (%count)', {
                  count: campaigns.filter((c) =>
                    dayjs(c.startDate).isAfter(dayjs())
                  ).length,
                })}
                <i>expand_more</i>
                <div className="Home__separatorBar" />
              </div>
            )}
          >
            <div className="Home__campaigns">
              {campaigns
                .filter((c) => dayjs(c.startDate).isAfter(dayjs()))
                .map((c) => campaignCard(c))}
            </div>
          </Accordion>
          <Accordion
            titleRenderer={() => (
              <div className="Home__separator">
                {t('Formations passées (%count)', {
                  count: campaigns.filter((c) =>
                    dayjs(c.endDate).isBefore(dayjs())
                  ).length,
                })}
                <i>expand_more</i>
                <div className="Home__separatorBar" />
              </div>
            )}
          >
            <div className="Home__campaigns">
              {campaigns
                .filter((c) => dayjs(c.endDate).isBefore(dayjs()))
                .map((c) => campaignCard(c))}
            </div>
          </Accordion>
          {['admin', 'superadmin'].includes(store.store.JWT.role) && (
            <Accordion
              titleRenderer={() => (
                <div className="Home__separator">
                  {t('Formations archivées (%count)', {
                    count: archivedCampaigns.length,
                  })}
                  <i>expand_more</i>
                  <div className="Home__separatorBar" />
                </div>
              )}
            >
              <div className="Home__campaigns">
                {archivedCampaigns.map((c) =>
                  archivedCampaignCard(c)
                )}
              </div>
            </Accordion>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Home;
