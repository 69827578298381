import { UUID } from './types/common';
import Theme from './types/theme';
import User from './types/user';
import Campaign from './types/campaign';
import UserList from './types/user_list';
// import * as dotenv from 'dotenv'

export enum Method {
  Get = 'get',
  Post = 'post',
  Patch = 'patch',
  Delete = 'delete',
}

export interface Route {
  path: string;
  method: Method;
  data?: any;
  params?: any;
  auth?: boolean;
  file?: File;
  extern?: boolean;
  skipHumps?: boolean;
  headers?: any;
  downloadFilename?: string;
}

// dotenv.config();
export const BASE_URL: string = process.env.BASE_URL_API as string;

const ROUTES: { [name: string]: (payload?: any) => Route } = {
  LOGIN: (data: { email: string; password: string }) => ({
    path: '/users/login',
    method: Method.Post,
    auth: false,
    data,
  }),
  FETCH_THEMES: () => ({
    path: '/themes',
    method: Method.Get,
    auth: true,
  }),
  FETCH_CAMPAIGN_TEMPLATES: () => ({
    path: '/campaign_templates',
    method: Method.Get,
    auth: true,
  }),
  FETCH_CAMPAIGNS: (data: { all: boolean }) => ({
    path: `/campaigns${data.all ? '?all=1' : ''}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_ARCHIVED_CAMPAIGNS: (data: { all: boolean }) => ({
    path: `/campaigns/${data.all ? '1' : ''}/archived_campaigns`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_CAMPAIGN: (data: { id: UUID }) => ({
    path: `/campaigns/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  DELETE_CAMPAIGN: (data: { id: UUID }) => ({
    path: `/campaigns/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  DELETE_USER_LIST: (data: { id: UUID }) => ({
    path: `/user_lists/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  DELETE_USER: (data: { id: UUID }) => ({
    path: `/users/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  FETCH_CAMPAIGNS_STATS: () => ({
    path: '/campaigns/stats',
    method: Method.Get,
    auth: true,
  }),
  FETCH_LANGUAGES: () => ({
    path: '/languages',
    method: Method.Get,
    auth: true,
  }),
  UPDATE_ME: (data) => ({
    path: '/users/me',
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_ME: () => ({
    path: '/users/me',
    method: Method.Get,
    auth: true,
  }),
  CREATE_SESSION: (data: { session: { themes?: Theme[] } }) => ({
    path: '/sessions',
    method: Method.Post,
    auth: true,
    data,
  }),
  FETCH_SESSION: (data: { id: UUID }) => ({
    path: `/sessions/${data.id}`,
    method: Method.Get,
    auth: true,
    data,
  }),
  SUBMIT_SESSION_CONTENT_ANSWER: (data: {
    sessionContentAssociation: { id: UUID; selectedAnswers: UUID[] };
  }) => ({
    path: `/session_content_associations/${data.sessionContentAssociation.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  UPDATE_USER_LIST: (data: { id: UUID; users: User[] }) => ({
    path: `/user_lists/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  UPDATE_USER: (data: { user: User }) => ({
    path: `/users/${data.user.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  IMPORT_LIST: (data: UserList & { file: File }) => ({
    path: `/user_lists/import`,
    method: Method.Post,
    auth: true,
    data,
    file: data.file,
  }),
  FETCH_USER_LISTS: () => ({
    path: '/user_lists',
    method: Method.Get,
    auth: true,
  }),
  FETCH_USER: (data: { userId: UUID }) => ({
    path: `/users/${data.userId}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_USERS: (data: { licenseId?: UUID }) => ({
    path: '/users',
    method: Method.Get,
    auth: true,
    data,
  }),
  CREATE_USER: (data: { user: User }) => ({
    path: '/users',
    method: Method.Post,
    auth: true,
    data,
  }),
  CREATE_DASHBOARD_LAYOUT: (data: {
    userId: UUID;
    dashboard_layout: JSON;
  }) => ({
    path: '/users/dashboard_layout',
    method: Method.Post,
    auth: true,
    data,
  }),
  UPDATE_DASHBOARD_LAYOUT: (data: {
    userId: UUID;
    dashboard_layout: JSON;
  }) => ({
    path: '/users/dashboard_layout',
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_DASHBOARD_LAYOUT: (data: { userId: UUID }) => ({
    path: `/users/${data.userId}/dashboard_layout`,
    method: Method.Get,
    auth: true,
    data,
  }),
  CREATE_DASHBOARD_ITEMS: (data: {
    userId: UUID;
    dashboard_items: JSON;
  }) => ({
    path: '/users/dashboard_items',
    method: Method.Post,
    auth: true,
    data,
  }),
  UPDATE_DASHBOARD_ITEMS: (data: {
    userId: UUID;
    dashboard_items: JSON;
  }) => ({
    path: '/users/dashboard_items',
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_DASHBOARD_ITEMS: (data: { userId: UUID }) => ({
    path: `/users/${data.userId}/dashboard_items`,
    method: Method.Get,
    auth: true,
    data,
  }),
  CREATE_USER_LIST: (data: { name: string; users: User[] }) => ({
    path: '/user_lists',
    method: Method.Post,
    auth: true,
    data,
  }),
  CREATE_CAMPAIGN: (data: { campaign: Campaign }) => ({
    path: '/campaigns',
    method: Method.Post,
    auth: true,
    data,
  }),
  UPDATE_CAMPAIGN: (data: { campaign: Campaign }) => ({
    path: `/campaigns/${data.campaign.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_CAMPAIGN_USERS_DATA: (data: {
    campaignId: UUID;
    filename: string;
  }) => ({
    path: `/campaigns/${data.campaignId}/users_data`,
    method: Method.Get,
    auth: true,
    data,
    downloadFilename: data.filename,
  }),
  FETCH_USER_CAMPAIGNS: (data: { userId: UUID }) => ({
    path: `/users/${data.userId}/user_campaigns`,
    method: Method.Get,
    auth: true,
    data,
  }),
  FETCH_USERS_CAMPAIGNS: (data: { usersIds: UUID[] }) => ({
    path: `/users/${data.usersIds}/users_campaigns`,
    method: Method.Get,
    auth: true,
    data,
  }),
  ASK_PASSWORD_RESET: (data: { email: string }) => ({
    path: `/users/reset_password`,
    method: Method.Post,
    data,
  }),
  RESET_PASSWORD: (data: { password: string; token: string }) => ({
    path: `/users/reset_password/${data.token}`,
    method: Method.Patch,
    data,
  }),
  UPLOAD_FILE: (data: { file: File }) => ({
    path: `/uploads`,
    method: Method.Post,
    auth: true,
    file: data.file,
  }),
  //! TEMPORARY
  RELOCATE_FILES: (data: {
    id: string;
    file: File;
    url: string;
  }) => ({
    path: `/uploads/relocate`,
    method: Method.Post,
    auth: true,
    data,
    file: data.file,
  }),
  FETCH_UPLOADS: () => ({
    path: `/uploads`,
    method: Method.Get,
    auth: true,
  }),
};

export default ROUTES;
