import React, { useState, useEffect, Fragment } from 'react'
import dayjs from 'dayjs'
import { t } from '../../services/i18n'
import './CampaignDetails.scss'
import Campaign from '../../types/campaign'
import { useParams } from 'react-router-dom'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import Loader from 'react-loader-spinner'
import StormBackground from '../../../assets/images/storm.png'
import RainBackground from '../../../assets/images/rain.png'
import CloudBackground from '../../../assets/images/cloud.png'
import SunBackground from '../../../assets/images/sun.png'
import StormIcon from '../../../assets/images/storm_icon.png'
import RainIcon from '../../../assets/images/rain_icon.png'
import CloudIcon from '../../../assets/images/cloud_icon.png'
import SunIcon from '../../../assets/images/sun_icon.png'
import User from '../../types/user'
import Button from '../../components/Button'
import ProgressBar from '../../components/ProgressBar'
import { Pie, Bar, Radar } from 'react-chartjs-2'

export default () => {
  const [campaign, setCampaign] = useState<Campaign>(undefined)
  const { id } = useParams<any>()
  const [loading, setLoading] = useState(true)
  const totalDuration = campaign
    ? dayjs(campaign.endDate).diff(campaign.startDate, 'day')
    : 1
  const leftDuration = campaign
    ? dayjs(campaign.endDate).diff(dayjs(), 'day')
    : 0
  const leftDurationPercentage = campaign
    ? Math.round((leftDuration / totalDuration) * 100)
    : 0
  const [users, setUsers] = useState<User[]>([])
  const manager = campaign
    ? users.find((u) => u.id == campaign.userId)
    : undefined

  useEffect(() => {
    fetchCampaign()
    fetchUsers()
  }, [])

  useEffect(() => {
    setLoading(!campaign)
  }, [campaign])

  const fetchUsers = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_USERS({ license_id: store.store.JWT.license_id })
      )

      setUsers(res)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les utilisateurs'))
      console.warn(e)
    }
  }

  const fetchCampaign = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CAMPAIGN({ id }))

      setCampaign(res)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer la formation'))
      console.warn(e)
    }
  }

  const downloadUserData = async () => {
    try {
      await httpClient.req(
        ROUTES.FETCH_CAMPAIGN_USERS_DATA({
          campaignId: id,
          filename: t('donnees_utilisateurs.xls'),
        })
      )
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les données utilisateurs')
      )
      console.warn(e)
    }
  }

  const backgroundForMeteo = () => {
    const involvement =
      (campaign.stats.lastSevenDaysActiveUsersCount * 100) /
      campaign.stats.usersCount

    if (involvement < 25) {
      return StormBackground
    } else if (involvement >= 25 && involvement < 50) {
      return RainBackground
    } else if (involvement >= 50 && involvement < 75) {
      return CloudBackground
    } else {
      return SunBackground
    }
  }

  const iconForMeteo = () => {
    const involvement =
      (campaign.stats.lastSevenDaysActiveUsersCount * 100) /
      campaign.stats.usersCount

    if (involvement < 25) {
      return StormIcon
    } else if (involvement >= 25 && involvement < 50) {
      return RainIcon
    } else if (involvement >= 50 && involvement < 75) {
      return CloudIcon
    } else {
      return SunIcon
    }
  }

  const nameForMeteo = () => {
    const involvement =
      (campaign.stats.lastSevenDaysActiveUsersCount * 100) /
      campaign.stats.usersCount

    if (involvement < 25) {
      return t('Orageux')
    } else if (involvement >= 25 && involvement < 50) {
      return t('Pluvieux')
    } else if (involvement >= 50 && involvement < 75) {
      return t('Variable')
    } else {
      return t('Radieux')
    }
  }

  return (
    <div className="CampaignDetails">
      {loading ? (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      ) : (
        <Fragment>
          <div className="CampaignDetails__header">
            <div
              className="CampaignDetails__headerMeteo"
              style={{ background: `url(${backgroundForMeteo()})` }}
            >
              <div>
                <div className="CampaignDetails__headerRegularity">
                  {t('Régularité des apprenants : ')}{' '}
                  <span>{nameForMeteo()}</span>
                </div>
                <div className="CampaignDetails__headerDate">
                  {t('Semaine du %start au %end', {
                    start: dayjs().subtract(7, 'day').format('L'),
                    end: dayjs().format('L'),
                  })}
                </div>
              </div>
              <div>
                <div className="CampaignDetails__headerMeteoIconBackground" />
                <img
                  src={iconForMeteo()}
                  className="CampaignDetails__headerMeteoIcon"
                />
              </div>
            </div>
            <div className="CampaignDetails__headerInfos">
              <div className="CampaignDetails__headerInfosTop">
                <div className="CampaignDetails__headerInfosTopLeft">
                  <div className="CampaignDetails__headerTitle">
                    {campaign.title}
                  </div>
                  {manager && (
                    <div className="CampaignDetails__headerUser">
                      {t('créée par %user', {
                        user: `${manager.firstName} ${manager.lastName}`,
                      })}
                    </div>
                  )}
                </div>
                {/* <div className="CampaignDetails__headerInfosTopRight">
                  <label>
                    <input type="checkbox" />
                    {t('Recevoir les rapports journaliers')}
                  </label>
                </div> */}
              </div>
              <div className="CampaignDetails__headerInfosBottom">
                <div className="CampaignDetails__headerInfosBottomLeft">
                  <div className="CampaignDetails__headerInfosBottomCountdown">
                    {dayjs().isBefore(campaign.endDate)
                      ? t('J - %left', {
                          left: leftDuration,
                        })
                      : t('Terminée')}
                  </div>
                  <div className="CampaignDetails__headerInfosBottomProgress">
                    <div className="CampaignDetails__headerInfosBottomProgressDates">
                      <div className="CampaignDetails__headerInfosBottomProgressDatesStart">
                        {t('Début : %start', {
                          start: dayjs(campaign.startDate).format('L'),
                        })}
                      </div>
                      <div className="CampaignDetails__headerInfosBottomProgressDatesEnd">
                        {t('Fin : %end', {
                          end: dayjs(campaign.endDate).format('L'),
                        })}
                      </div>
                    </div>
                    <ProgressBar
                      progress={100 - leftDurationPercentage}
                      colorStart="#f8a830"
                      colorEnd="#fabe37"
                    />
                    <div className="CampaignDetails__headerInfosBottomProgressElapsed">
                      {t('temps écoulé : %elapsed%', {
                        elapsed: 100 - leftDurationPercentage,
                      })}
                    </div>
                  </div>
                </div>
                <div className="CampaignDetails__headerInfosBottomRight">
                  <Button
                    title={t('Télécharger les données utilisateurs')}
                    onClick={downloadUserData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="CampaignDetails__details">
            <div className="CampaignDetails__detailsCard">
              <div className="CampaignDetails__detailsCardHeader">
                <div className="CampaignDetails__detailsCardTitle">
                  {t('Taux de participation')}
                </div>
              </div>
              <div className="CampaignDetails__detailsCardBody CampaignDetails__detailsCardBody--chart">
                <div className="CampaignDetails__detailsCardBodyTag">
                  <div className="CampaignDetails__detailsCardBodyTagEntry">
                    <div className="CampaignDetails__detailsCardBodyTagEntryValue">
                      {campaign.stats.usersCount}
                    </div>
                    <div className="CampaignDetails__detailsCardBodyTagEntryLabel">
                      {t('Inscriptions')}
                    </div>
                  </div>
                  <div className="CampaignDetails__detailsCardBodyTagEntry">
                    <div className="CampaignDetails__detailsCardBodyTagEntryValue">
                      {campaign.stats.activeUsersCount}
                    </div>
                    <div className="CampaignDetails__detailsCardBodyTagEntryLabel">
                      {t('apprenants en cours')}
                    </div>
                  </div>
                </div>
                <div className="CampaignDetails__detailsCardBodyChart">
                  <Pie
                    width={250}
                    height={250}
                    data={{
                      labels: [
                        t('Assignés sans connexion'),
                        t('1ère connexion effectuée, pas de session'),
                        t('Session démarrée, pas certifiable'),
                        t('Certification possible'),
                        t('Certifié'),
                      ],
                      datasets: [
                        {
                          data: [
                            campaign.stats.involvement.addedToCampaign,
                            campaign.stats.involvement.didConnect,
                            campaign.stats.involvement.didStartSession,
                            campaign.stats.involvement.certificationUnlocked,
                            campaign.stats.involvement
                              .certificationValidatedCount,
                          ],
                          backgroundColor: [
                            '#f06a2f',
                            '#f29e34',
                            '#45ace7',
                            '#3de3fd',
                            '#b4cc2a',
                          ],
                        },
                      ],
                    }}
                    options={{
                      legend: {
                        display: false,
                      },
                    }}
                  />
                </div>
                <div className="CampaignDetails__detailsCardBodyLegend">
                  <div className="CampaignDetails__detailsCardBodyLegendEntry">
                    <div
                      className="CampaignDetails__detailsCardBodyLegendEntryColor"
                      style={{ background: '#f06a2f' }}
                    />
                    <div className="CampaignDetails__detailsCardBodyLegendEntryTitle">
                      {t('Assignés sans connexion')} (
                      {campaign.stats.involvement.addedToCampaign})
                    </div>
                  </div>
                  <div className="CampaignDetails__detailsCardBodyLegendEntry">
                    <div
                      className="CampaignDetails__detailsCardBodyLegendEntryColor"
                      style={{ background: '#f29e34' }}
                    />
                    <div className="CampaignDetails__detailsCardBodyLegendEntryTitle">
                      {t('1ère connexion effectuée, pas de session')} (
                      {campaign.stats.involvement.didConnect})
                    </div>
                  </div>
                  <div className="CampaignDetails__detailsCardBodyLegendEntry">
                    <div
                      className="CampaignDetails__detailsCardBodyLegendEntryColor"
                      style={{ background: '#45ace7' }}
                    />
                    <div className="CampaignDetails__detailsCardBodyLegendEntryTitle">
                      {t('Session démarrée, pas certifiable')} (
                      {campaign.stats.involvement.didStartSession})
                    </div>
                  </div>
                  <div className="CampaignDetails__detailsCardBodyLegendEntry">
                    <div
                      className="CampaignDetails__detailsCardBodyLegendEntryColor"
                      style={{ background: '#3de3fd' }}
                    />
                    <div className="CampaignDetails__detailsCardBodyLegendEntryTitle">
                      {t('Certification possible')} (
                      {campaign.stats.involvement.certificationUnlocked})
                    </div>
                  </div>
                  <div className="CampaignDetails__detailsCardBodyLegendEntry">
                    <div
                      className="CampaignDetails__detailsCardBodyLegendEntryColor"
                      style={{ background: '#b4cc2a' }}
                    />
                    <div className="CampaignDetails__detailsCardBodyLegendEntryTitle">
                      {t('Certifié')} (
                      {campaign.stats.involvement.certificationValidatedCount})
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="CampaignDetails__detailsCard">
              <div className="CampaignDetails__detailsCardHeader">
                <div className="CampaignDetails__detailsCardTitle">
                  {t('Taux de complétion')}
                </div>
              </div>
              <div className="CampaignDetails__detailsCardBody CampaignDetails__detailsCardBody--chart">
                <div className="CampaignDetails__detailsCardBodyChart">
                  <Pie
                    width={250}
                    height={250}
                    data={{
                      labels: ['0-25%', '25-50%', '50-75%', '75-100%'],
                      datasets: [
                        {
                          data: [
                            campaign.stats.completion.twentyFive,
                            campaign.stats.completion.fifty,
                            campaign.stats.completion.seventyFive,
                            campaign.stats.completion.hundred,
                          ],
                          backgroundColor: [
                            '#f06a2f',
                            '#f29e34',
                            '#3de3fd',
                            '#b4cc2a',
                          ],
                        },
                      ],
                    }}
                    options={{
                      legend: {
                        display: false,
                      },
                    }}
                  />
                </div>
                <div className="CampaignDetails__detailsCardBodyLegend">
                  <div className="CampaignDetails__detailsCardBodyLegendEntry">
                    <div
                      className="CampaignDetails__detailsCardBodyLegendEntryColor"
                      style={{ background: '#f06a2f' }}
                    />
                    <div className="CampaignDetails__detailsCardBodyLegendEntryTitle">
                      {'0-25%'} ({campaign.stats.completion.twentyFive})
                    </div>
                  </div>
                  <div className="CampaignDetails__detailsCardBodyLegendEntry">
                    <div
                      className="CampaignDetails__detailsCardBodyLegendEntryColor"
                      style={{ background: '#f29e34' }}
                    />
                    <div className="CampaignDetails__detailsCardBodyLegendEntryTitle">
                      {'25-50%'} ({campaign.stats.completion.fifty})
                    </div>
                  </div>
                  <div className="CampaignDetails__detailsCardBodyLegendEntry">
                    <div
                      className="CampaignDetails__detailsCardBodyLegendEntryColor"
                      style={{ background: '#3de3fd' }}
                    />
                    <div className="CampaignDetails__detailsCardBodyLegendEntryTitle">
                      {'50-75%'} ({campaign.stats.completion.seventyFive})
                    </div>
                  </div>
                  <div className="CampaignDetails__detailsCardBodyLegendEntry">
                    <div
                      className="CampaignDetails__detailsCardBodyLegendEntryColor"
                      style={{ background: '#b4cc2a' }}
                    />
                    <div className="CampaignDetails__detailsCardBodyLegendEntryTitle">
                      {'75-100%'} ({campaign.stats.completion.hundred})
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="CampaignDetails__detailsCard">
              <div className="CampaignDetails__detailsCardHeader">
                <div className="CampaignDetails__detailsCardTitle">
                  {t('Certification')}
                </div>
              </div>
              <div className="CampaignDetails__detailsCardBody CampaignDetails__detailsCardBody--chart">
                <div className="CampaignDetails__detailsCardBodyTag">
                  <div className="CampaignDetails__detailsCardBodyTagEntry">
                    <div className="CampaignDetails__detailsCardBodyTagEntryValue">
                      {Math.floor(
                        (campaign.stats.certificationValidatedCount * 100) /
                          campaign.stats.usersCount
                      )}
                      %
                    </div>
                    <div className="CampaignDetails__detailsCardBodyTagEntryLabel">
                      {t('apprenants certifiés')}
                    </div>
                  </div>
                </div>
                <div className="CampaignDetails__detailsCardBodyChart--bar">
                  <Bar
                    width={400}
                    height={250}
                    data={{
                      labels: [t('1 essai'), t('2 essais'), t('+ 2 essais')],
                      datasets: [
                        {
                          data: [
                            campaign.stats.certification.oneTry,
                            campaign.stats.certification.twoTries,
                            campaign.stats.certification.moreThanTwoTries,
                          ],
                          backgroundColor: ['#b4cc2a', '#3de3fd', '#45ace7'],
                        },
                      ],
                    }}
                    options={{
                      legend: {
                        display: false,
                      },
                      scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="CampaignDetails__detailsCard">
              <div className="CampaignDetails__detailsCardHeader">
                <div className="CampaignDetails__detailsCardTitle">
                  {t('Performance par thème')}
                </div>
              </div>
              <div className="CampaignDetails__detailsCardBody CampaignDetails__detailsCardBody--chart">
                <div className="CampaignDetails__detailsCardBodyChart--bar">
                  <Radar
                    width={400}
                    height={300}
                    data={{
                      labels: campaign.stats.themePerformance.map(
                        (tc) => tc.name
                      ),
                      datasets: [
                        {
                          data: campaign.stats.themePerformance.map(
                            (tc) => tc.performance
                          ),
                          backgroundColor: 'rgba(42, 204, 111, 0.2)',
                          borderColor: '#4b4d53',
                          pointRadius: 7.5,
                          pointBorderColor: 'white',
                          pointBorderWidth: 2,
                          pointBackgroundColor: [
                            '#f06a2f',
                            '#f29e34',
                            '#b4cc2a',
                            '#45ace7',
                            '#3de3fd',
                            '#2accc2',
                            '#2acc6f',
                            '#b4cc2a',
                            '#f06a2f',
                            '#f29e34',
                            '#b4cc2a',
                            '#45ace7',
                            '#3de3fd',
                            '#2accc2',
                            '#2acc6f',
                            '#b4cc2a',
                          ],
                        },
                      ],
                    }}
                    options={{
                      legend: {
                        display: false,
                      },
                      scale: {
                        ticks: {
                          min: 0,
                          max: 1,
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  )
}
