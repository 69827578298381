import React from 'react'
import './InputWithLabel.scss'

interface Props {
  label?: string
  type?: string
  value: string | string[]
  selectMultiple?: boolean
  selectOptions?: any[]
  selectLabelKey?: string
  selectValueKey?: string
  onChange: (v: any) => void
  disabled?: boolean
  disableSorting?: boolean
  min?: string
}

export default (props: Props) => {
  return (
    <div className="InputWithLabel">
      {props.label && (
        <div className="InputWithLabel__label">{props.label}</div>
      )}
      <div className="InputWithLabel__input">
        {props.selectOptions ? (
          <select
            multiple={props.selectMultiple}
            disabled={props.disabled}
            value={props.value}
            onChange={(e) =>
              props.onChange(
                props.selectMultiple
                  ? Array.from(e.target.selectedOptions).map((o) => o.value)
                  : e.target.value
              )
            }
          >
            <option value={undefined} hidden></option>
            {(props.disableSorting
              ? props.selectOptions
              : props.selectOptions.sort((v1, v2) =>
                v1[props.selectLabelKey] != null && v1[props.selectLabelKey] != undefined && v1[props.selectLabelKey].toLowerCase() >=
                  v2[props.selectLabelKey] != null && v2[props.selectLabelKey] != undefined && v2[props.selectLabelKey].toLowerCase()
                  ? 1
                  : -1)
            ).map((o) => (
              <option
                key={o[props.selectValueKey]}
                value={o[props.selectValueKey]}
              >
                {o[props.selectLabelKey]}
              </option>
            ))}
          </select>
        ) : (
          <input
            disabled={props.disabled}
            onChange={(e) => props.onChange(e.target.value)}
            type={props.type}
            value={props.value}
            min={props.min}
          />
        )}
      </div>
    </div>
  )
}
