import React, { useEffect, useState } from 'react'
import Stepper, { Step } from 'react-material-stepper'
import { t } from '../../services/i18n'
import './CampaignCreation.scss'
import CampaignCreationTitle from './CampaignCreationTitle'
import CampaignCreationTemplate from './CampaignCreationTemplate'
import CampaignCreationUsers from './CampaignCreationUsers'
import CampaignCreationSchedule from './CampaignCreationSchedule'
import CampaignCreationRecap from './CampaignCreationRecap'
import { useParams } from 'react-router-dom'
import Campaign from '../../types/campaign'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import Loader from 'react-loader-spinner'

interface Props {
  editMod: boolean
}

export default (editMod: Props) => {
  const { id } = useParams()
  const [campaign, setCampaign] = useState<Campaign>({
    title: '',
    description: '',
    startDate: undefined,
    endDate: undefined,
    licenseId: store.store.JWT.licenseId,
    campaignTemplateId: undefined,
    oneShot: false,
    certificationUnlockThreshold: 50,
    certificationMaxErrors: 5,
    certificationPeriod: 'week',
    certificationTriesPerPeriod: 1,
    users: [],
    userId: store.store.JWT.sub,
    manualEmail: false,
    certificationQuestionsCount: 20,
  })
  const [loading, setLoading] = useState(true)
  let [callback, setCallback] = useState<(c: Campaign) => void>(undefined)

  useEffect(() => {
    if (id) {
      fetchCampaign()
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (callback) {
      setTimeout(() => callback(campaign), 500)
      setCallback(undefined)
    }
  }, [campaign])

  const fetchCampaign = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CAMPAIGN({ id }))

      setCampaign(res)
      setLoading(false)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer la formation'))
      console.warn(e)
    }
  }

  return (
    <div className="CampaignCreation">
      {loading ? (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      ) : (
        <Stepper initialStep={'title'}>
          <Step stepId="title" title={t('Définir la formation')} disabled={true}>
            <CampaignCreationTitle campaign={campaign} />
          </Step>
          {/* <Step stepId="type" title={t('Choisir le type de formation')}>
            <CampaignCreationType campaign={campaign} />
          </Step> */}
          <Step stepId="template" title={t('Choisir le modèle')} disabled={true}>
            <CampaignCreationTemplate campaign={campaign} />
          </Step>
          <Step stepId="users" title={t('Assigner les apprenants')} disabled={true}>
            {editMod ? (
              <CampaignCreationUsers campaign={campaign} editMod={true} />
            ) : (
              <CampaignCreationUsers campaign={campaign} editMod={false} />
            )}
          </Step>
          <Step stepId="schedule" title={t('Planifier la formation')} disabled={true}>
            <CampaignCreationSchedule campaign={campaign} />
          </Step>
          <Step stepId="recap" title={t('Récapitulatif')} disabled={true}>
            <CampaignCreationRecap campaign={campaign} />
          </Step>
        </Stepper>
      )}
    </div>
  )
}
