import React, { useContext, useState, useEffect, Fragment } from 'react'
import './CampaignCreationUsers.scss'
import InputWithLabel from '../../../components/InputWithLabel'
import { t } from '../../../services/i18n'
import Button from '../../../components/Button'
import { StepperContext } from 'react-material-stepper'
import store, { Message } from '../../../services/store'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import UserList from '../../../types/user_list'
import { UUID } from '../../../types/common'
import User from '../../../types/user'
import Modal from '../../../components/Modal'
import UserForm from '../../UserForm'
import ListForm from '../../ListForm'
import Campaign from '../../../types/campaign'
import Select from 'react-select'
import { GrUserAdd } from 'react-icons/gr'
interface Props {
  campaign: Campaign
  editMod: boolean
}

export default (props: Props) => {
  const { resolve, getData } = useContext(StepperContext)
  const [campaign, _] = useState(
    getData('users')?.campaign ||
    getData('template')?.campaign ||
    props.campaign
  )
  const [lists, setLists] = useState<UserList[]>([])
  const [selectedLists, setSelectedLists] = useState<UUID[]>([])
  const [selectedUser, setSelectedUser] = useState<UUID>(undefined)
  const [users, setUsers] = useState<User[]>(props.campaign.users || [])
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [displayModal, setDisplayModal] = useState(false)
  const [displayListModal, setDisplayListModal] = useState(false)

  useEffect(() => {
    fetchLists()
    fetchUsers()
  }, [])

  useEffect(() => {
    let listsUsers: User[] = []

    selectedLists.forEach((l) => {
      let list = lists.find((l2) => l2.id == l)

      if (list) {
        listsUsers = [
          ...listsUsers,
          ...list.users.filter((u) => !listsUsers.find((u2) => u.id == u2.id)),
        ]
      }
    })
    setUsers(listsUsers)
  }, [selectedLists])

  const goToNextStep = () => {
    resolve({ campaign: { ...campaign, users } })
  }

  const fetchLists = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_USER_LISTS())

      setLists(res)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les listes'))
      console.warn(e)
    }
  }

  const fetchUsers = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_USERS({ license_id: store.store.JWT.license_id })
      )

      setAllUsers(res)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les listes'))
      console.warn(e)
    }
  }

  const addUserFromSelectedUser = () => {
    if (!users.find((u) => u.id == selectedUser)) {
      const user = allUsers.find((u) => u.id == selectedUser)

      setUsers([...users, user])
    }
  }

  const removeUser = (id: UUID) => {
    setUsers(users.filter((u) => u.id != id))
  }

  const createAndAddUser = async (user: User) => {
    try {
      let res = await httpClient.req(ROUTES.CREATE_USER({ user }))

      setUsers([...users, res])
      setAllUsers([...allUsers, res])
      setDisplayModal(false)
    } catch (e) {
      store.notify(
        Message.Error,
        t(
          "Impossible de créer l'apprenant. L'email est peut-être déjà utilisé."
        )
      )
      console.warn(e)
    }
  }

  const submitList = async (list: UserList & { file?: File }) => {
    try {
      let res = await httpClient.req(
        ROUTES.IMPORT_LIST({ file: list.file, name: list.name })
      )
      let newUsers = [...users, ...res.users]

      setLists([...lists, res])
      setUsers(
        newUsers.filter(
          (u, i) =>
            newUsers.findIndex((u2) => u2.id == u.id) == -1 ||
            newUsers.findIndex((u2) => u2.id == u.id) == i
        )
      )
      setSelectedLists([...selectedLists, res.id])
      setDisplayListModal(false)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de sauvegarder la liste.'))
      console.warn(e)
    }
  }

  const canContinue = () => {
    return users.length > 0
  }

  return (
    <div className="CampaignCreationUsers">
      {displayModal && (
        <Modal dismiss={() => setDisplayModal(false)}>
          <UserForm onSubmit={createAndAddUser} />
        </Modal>
      )}
      {displayListModal && (
        <Modal dismiss={() => setDisplayListModal(false)}>
          <ListForm onSubmit={submitList} />
        </Modal>
      )}
      <div className="section__title">{t('Assigner des apprenants')}</div>
      <div className="section__body" style={{ padding: "3em 3em" }}>
        <div style={{ width: "100%" }}>
          <div className="CampaignCreationUsers__add">
            <Fragment>
              <div className='formHeader'>
                <div className='formHeader__title'>
                  {t('Ajouter une liste existante')}
                </div>
                <div className='formHeader__body'>
                  <div className="CampaignCreationUsers__addFromList" >
                    <Select
                      isMulti
                      options={lists.map((l) => ({
                        value: l.id,
                        label: l.name,
                      }))}
                      value={selectedLists.map((l) => {
                        let list = lists.find((l2) => l2.id == l)

                        return { value: list.id, label: list.name }
                      })}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(v) => {
                        setSelectedLists(v.map((l) => l.value))
                      }
                      } />
                  </div>
                  <div className="CampaignCreationUsers__createList">
                    <Button
                      title={t('Créer une nouvelle liste')}
                      onClick={() => setDisplayListModal(true)}
                    />
                  </div>
                </div>
              </div>
              <div className='formBody'>
                <div className='formBody__title'>
                  {t('Ajouter un apprenant existant')}
                </div>
                <div className='formBody__body'>
                  <div className="CampaignCreationUsers__addFromUsers">
                    <InputWithLabel
                      value={selectedUser}
                      selectOptions={allUsers}
                      selectLabelKey="email"
                      selectValueKey="id"
                      onChange={(v: string) => setSelectedUser(v)}
                    />
                    <button className='userAddButton' disabled={
                      !selectedUser || !!users.find((u) => u.id == selectedUser)
                    } onClick={addUserFromSelectedUser}>
                      <GrUserAdd style={{ width: "1.5em", height: "1.5em" }} />
                    </button>
                    {/* <Button
                    disabled={
                      !selectedUser || !!users.find((u) => u.id == selectedUser)
                    }
                    title={
                      users.find((u) => u.id == selectedUser)
                        ? GrUserAdd
                        : GrUserAdd
                      }
                      onClick={addUserFromSelectedUser}
                    /> */}
                  </div>
                  <div className="CampaignCreationUsers__addManually">
                    <Button
                      title={t('Créer un apprenant manuellement')}
                      onClick={() => setDisplayModal(true)}
                    />
                  </div>
                </div>
              </div>
              <div className="section__actions">
                {props.editMod ? (
                  <Button
                    title={t('Suivant')}
                    onClick={goToNextStep}
                    disabled={false}
                  />
                ) : (
                  <Button
                    title={t('Suivant')}
                    onClick={goToNextStep}
                    disabled={!canContinue()}
                  />
                )}
              </div>
            </Fragment>
          </div>
          <div className="CampaignCreationUsers__list">
            <div className="CampaignCreationUsers__listTitle">
              {t('Liste des utilisateurs')}
            </div>
            <div className="CampaignCreationUsers__listBody">
              <ul>
                {users.map((u) => (
                  <li
                    key={u.id}
                    className="CampaignCreationUsers__listBodyEntry"
                  >
                    <i onClick={() => removeUser(u.id)}>close</i>
                    {u.firstName} {u.lastName} - {u.email}{' '}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
