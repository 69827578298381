export default {
    "créée par %user": {
        "fr": "créée par %user",
        "en": "created by %user"
    },
    "Début : %start": {
        "fr": "Début : %start",
        "en": "Start : %start"
    },
    "Fin : %end": {
        "fr": "Fin : %end",
        "en": "End : %end"
    },
    "Télécharger les données utilisateurs": {
        "fr": "Télécharger les données utilisateurs",
        "en": "Download user data"
    },
    "Assignés sans connexion": {
        "fr": "Assignés sans connexion",
        "en": "Assigned without connection"
    },
    "1ère connexion effectuée, pas de session": {
        "fr": "1ère connexion effectuée, pas de session",
        "en": "First connection done, no session started"
    },
    "Session démarrée, pas certifiable": {
        "fr": "Session démarrée, pas certifiable",
        "en": "Session started, not ready for certification"
    },
    "Certification possible": {
        "fr": "Certification possible",
        "en": "Ready for certification"
    },
    "Certifié": {
        "fr": "Certifié",
        "en": "Certified"
    },
    "1 essai": {
        "fr": "1 essai",
        "en": "1 try"
    },
    "2 essais": {
        "fr": "2 essais",
        "en": "2 tries"
    },
    "+ 2 essais": {
        "fr": "+ 2 essais",
        "en": "+ 2 tries"
    },
    "temps écoulé : %elapsed%" : {
        "fr": "temps écoulé : %elapsed%",
        "en": "elapsed time : %elapsed%"
    },
    "Taux de complétion": {
        "fr": "Taux de complétion",
        "en": "Completion rate"
    },
    "Performance par thème": {
        "fr": "Performance par thème",
        "en": "Performance by theme"
    },
    "Certification": {
        "fr": "Certification",
        "en": "Certification"
    },
    "apprenants en cours": {
        "fr": "apprenants en cours",
        "en": "Users in progress"
    },
    "J - %left": {
        "fr": "J - %left",
        "en": "D - %left"
    },
    "Créer une nouvelle liste": {
        "fr": "Créer une nouvelle liste",
        "en": "Create a new list"
    },
}