import React, { useContext, useEffect, useState } from "react";
import "./CampaignCreationSchedule.scss";
import { t } from "../../../services/i18n";
import Button from "../../../components/Button";
import { StepperContext } from "react-material-stepper";
import Calendar from "react-calendar";
import TimePicker from "react-time-picker";
import dayjs from "dayjs";
import Content from "../../../types/content";
import store from "../../../services/store";
import Campaign from "../../../types/campaign";

interface Props {
  campaign: Campaign;
}

export default (props: Props) => {
  const { resolve, getData } = useContext(StepperContext);
  const [campaign, setCampaign] = useState(
    getData("schedule")?.campaign ||
      getData("users")?.campaign ||
      props.campaign
  );
  const [startTime, setStartTime] = useState<string>("07:00");
  const [endTime, setEndTime] = useState<string>("23:00");
  const totalContentDuration = (campaign?.campaignTemplate.contents || [])
    .map((c: Content) => c.duration)
    .reduce((a: number, b: number) => a + b, 0);
  const daysCount =
    campaign.endDate && campaign.startDate
      ? dayjs(campaign.endDate).diff(campaign.startDate, "day")
      : 0;
  const [mailer, setMailer] = useState<string>(null);
  useEffect(() => {
    let mailer = "mailto:";
    campaign.users.forEach((user: any, index: number) => {
      if (index !== campaign.users.length - 1)
        mailer = mailer + user.email + ";";
      else mailer = mailer + user.email;
    });
    mailer = mailer +=
      "?body=Use this link to set password : https://apprenant.vigiware.com/first_connection";
    setMailer(mailer);
  }, []);

  const goToRecap = () => {
    resolve({ campaign: { ...campaign } });
  };

  const setDates = (v: Date[] | Date) => {
    let startDate = dayjs((v as Date[])[0]);
    let endDate = dayjs((v as Date[])[1]);

    if (startTime) {
      startDate = startDate
        .set("hour", +startTime.split(":")[0])
        .set("minute", +startTime.split(":")[1]);
    }
    if (endTime) {
      endDate = endDate
        .set("hour", +endTime.split(":")[0])
        .set("minute", +endTime.split(":")[1]);
    }

    if (startDate && (v as Date[])[1] === undefined) {
      endDate = startDate
        .set("hour", +endTime.split(":")[0])
        .set("minute", +endTime.split(":")[1]);
    }

    setCampaign({
      ...campaign,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  };

  const updateStartTime = (v: string) => {
    setStartTime(v);
    if (campaign.startDate && v) {
      let startDate = dayjs(campaign.startDate);

      setCampaign({
        ...campaign,
        startDate: startDate
          .set("hour", +v.split(":")[0])
          .set("minute", +v.split(":")[1])
          .toDate(),
      });
    }
  };

  const updateEndTime = (v: string) => {
    setEndTime(v);
    if (campaign.endDate && v) {
      let endDate = dayjs(campaign.endDate);

      setCampaign({
        ...campaign,
        endDate: endDate
          .set("hour", +v.split(":")[0])
          .set("minute", +v.split(":")[1])
          .toDate(),
      });
    }
  };

  const displayableDuration = () => {
    const perDay = Math.floor(
      totalContentDuration / (daysCount == 0 ? 1 : daysCount)
    );
    const minutes = Math.floor(perDay / 60);
    const seconds = perDay % 60;

    return t("%min:%sec", { min: minutes, sec: seconds });
  };

  return (
    <div className="CampaignCreationSchedule">
      <div className="section__title">{t("Planifier la formation")}</div>
      <div className="section__body">
        <div className="CampaignCreationSchedule__schedule">
          <div className="CampaignCreationSchedule__dates">
            <div className="CampaignCreationSchedule__title">
              {t("Dates de début et fin de formation")}
            </div>
            <Calendar
              onChange={setDates}
              value={[
                campaign.startDate ? new Date(campaign.startDate) : undefined,
                campaign.endDate ? new Date(campaign.endDate) : undefined,
              ]}
              selectRange={true}
              allowPartialRange={true}
              minDate={new Date()}
              maxDate={
                store.store.JWT.license_exp &&
                new Date(store.store.JWT.license_exp * 1000)
              }
              // tileDisabled={() =>
              //   props.campaign.id && props.campaign.startDate < new Date()
              //     ? false
              //     : true
              // }
            />
          </div>
          <div className="CampaignCreationSchedule__hours">
            <div className="CampaignCreationSchedule__hoursStart">
              <div className="CampaignCreationSchedule__title">
                {t("Heure de lancement")}
              </div>
              <TimePicker
                onChange={updateStartTime}
                value={startTime}
                disableClock
              />
            </div>
            <div className="CampaignCreationSchedule__hoursEnd">
              <div className="CampaignCreationSchedule__title">
                {t("Heure de fin")}
              </div>
              <TimePicker
                onChange={updateEndTime}
                value={endTime}
                disableClock
              />
            </div>
          </div>
        </div>
        <div className="CampaignCreationSchedule__startingEmail">
          <div className="CampaignCreationSchedule__title">
            {t("Email de lancement")}
          </div>
          <label>
            <input
              type="radio"
              checked={!campaign.manualEmail}
              onChange={(e) =>
                setCampaign({
                  ...campaign,
                  manualEmail: !e.target.checked,
                })
              }
            />
            {t("Inscription par mail automatique Vigiware")}
          </label>
          <br />
          <label>
            <input
              type="radio"
              checked={campaign.manualEmail}
              onChange={(e) =>
                setCampaign({
                  ...campaign,
                  manualEmail: e.target.checked,
                })
              }
            />
            {t("Inscription par mail envoyé par mes soins")}
            <div className="CampaignCreationSchedule__startingEmailHint">
              {t(
                "Ajoutez ce lien à votre mail pour que les utilisateurs puissent définir leur mot de passe si besoin : https://apprenant.vigiware.com/first_connection."
              )}
            </div>
          </label>
        </div>
        <div className="section__actions">
          {campaign.manualEmail ? (
            <>
              <a href={mailer} target="_blank">
                <Button
                  title={t("Récapitulatif")}
                  onClick={() => {
                    goToRecap();
                  }}
                  disabled={!campaign.startDate || !campaign.endDate}
                />
              </a>
            </>
          ) : (
            <Button
              title={t("Récapitulatif")}
              onClick={() => {
                goToRecap();
              }}
              disabled={!campaign.startDate || !campaign.endDate}
            />
          )}
        </div>
      </div>
    </div>
  );
};
