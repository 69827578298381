export default {
    "Bienvenue sur la plateforme": {
        fr: "Bienvenue sur la plateforme",
        en: "Welcome to the platform"
    },
    "Rentrez ici vos identifiants pour accéder à la plateforme": {
        fr: "Rentrez ici vos identifiants pour accéder à la plateforme",
        en: "Enter your credentials to access the platform"
    },
    "Mot de passe": {
        fr: "Mot de passe",
        en: "Password"
    },
    "Connexion": {
        fr: "Connexion",
        en: "Login"
    },
    "Vous avez oublié votre mot de passe ?": {
        fr: "Vous avez oublié votre mot de passe ?",
        en: "Forgot your password ?"
    },
    "Sensibilisez vos équipes, suivez leur progression jusqu\'à la certification.": {
        fr: "Sensibilisez vos équipes, suivez leur progression jusqu\'à la certification.",
        en: "Sensibilize your teams, follow their progress until the certification."
    }
}