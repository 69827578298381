import React from 'react'
import ReactDOM from 'react-dom'
import './default.scss'
import Layout from './app/Layout'
import 'toastify-js/src/toastify.css'
import 'react-material-stepper/dist/react-stepper.css'
import 'react-calendar/dist/Calendar.css'

const rootElement = document.getElementById('app')

ReactDOM.render(<Layout></Layout>, rootElement)
