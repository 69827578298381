import React, { useState } from "react";
import "./ListForm.scss";
import UserList from "../../types/user_list";
import InputWithLabel from "../../components/InputWithLabel";
import { t } from "../../services/i18n";
import Button from "../../components/Button";
import ListUsersCSV from "./../../../public/ressources/user_list.csv";
import ListUsersXLSX from "./../../../public/ressources/user_list.xlsx";

interface Props {
  list?: UserList;
  onSubmit: (list: UserList & { file?: File }) => void;
}

export default (props: Props) => {
  const [list, setList] = useState<any>(
    props.list || { name: "", file: undefined }
  );

  return (
    <div className="ListForm">
      <InputWithLabel
        label={t("Nom de la liste")}
        value={list.name}
        onChange={(v) => setList({ ...list, name: v })}
      />
      <input
        type="file"
        onChange={(e) => setList({ ...list, file: e.target.files[0] })}
        accept=".xls,.xlsx,.csv"
      />
      <div className="ListForm__hint">
        <div>{t("Modèles :")}</div>
        <a
          href={ListUsersCSV}
          download="/ressources/user_list.csv"
          target="_blank"
          rel="noopener noreferrer"
        >
          CSV
        </a>
        ,{" "}
        <a
          href={ListUsersXLSX}
          download="/ressources/user_list.xlsx"
          target="_blank"
          rel="noopener noreferrer"
        >
          XLSX
        </a>
      </div>
      <Button
        disabled={!list.name || list.name.length == 0 || !list.file}
        title={t("Créer")}
        onClick={props.onSubmit && (() => props.onSubmit(list as any))}
      />
    </div>
  );
};
