import React, { ComponentClass } from 'react'
import './LoginInput.scss'

interface Props {
  className?: string
  icon: ComponentClass
  after?: ComponentClass
  afterOnClick?: () => void
  type?: string
  value: string
  onChange: (v: string) => void
  placeholder?: string
}

export default (props: Props) => {
  let Icon = props.icon
  let After = props.after

  return (
    <div
      className={`LoginInput${props.className ? ` ${props.className}` : ''}`}
    >
      <div className="LoginInput__icon">
        <Icon />
      </div>
      <div className="LoginInput__separator" />
      <div className="LoginInput__input">
        <div className="LoginInput__inputContent">
          <input
            type={props.type || 'text'}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            placeholder={props.placeholder}
          />
        </div>
        {props.after && (
          <div className="LoginInput__inputAfter" onClick={props.afterOnClick}>
            <After />
          </div>
        )}
      </div>
    </div>
  )
}
