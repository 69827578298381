import React, { Component, useEffect, useRef, useState } from 'react'

import './tutorial.scss'
import { t } from '../../services/i18n'
import classNames from 'classnames'


export default () => {
    const [duration, setDuration] = useState(0)
    const [isDurationModified, setIsDurationModified] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        if (isDurationModified && duration !== undefined) {
            changeDuration(duration)
        }
    }, [duration])


    const changeDuration = (timer: number) => {
        if (videoRef !== undefined) {
            videoRef.current.currentTime = timer
            setIsDurationModified(false)
        }
    }

    var formationClassTab = classNames({
        "tutorialBodyTab__Selected": duration >= 0 && duration <= 19,
        "tutorialBodyTab__notSelected": duration > 19
    });

    var quizClassTab = classNames({
        "tutorialBodyTab__Selected": duration >= 19 && duration <= 45,
        "tutorialBodyTab__notSelected": duration > 45 || duration < 45
    });
    var formationThemeClassTab = classNames({
        "tutorialBodyTab__Selected": duration >= 45 && duration <= 75,
        "tutorialBodyTab__notSelected": duration > 75 || duration < 75
    });
    var profilClassTab = classNames({
        "tutorialBodyTab__Selected": duration >= 75 && duration <= 107,
        "tutorialBodyTab__notSelected": duration > 107 || duration < 107
    });
    var statistiquesClassTab = classNames({
        "tutorialBodyTab__Selected": duration >= 107 && duration <= 131,
        "tutorialBodyTab__notSelected": duration > 131 || duration < 131
    });
    var certificationClassTab = classNames({
        "tutorialBodyTab__Selected": duration >= 131,
        "tutorialBodyTab__notSelected": duration < 131
    });
    return (
        <div className='screen'>
            <div className='tutorial'>
                <div className='tutorialHeader'>
                    <div className='tutorialHeader__title'>
                        {t('Tutorial')}
                    </div>
                </div>
                <div className='tutorialBody'>
                    <div className='tutorialBodyTab'>
                        <div className={formationClassTab} onClick={() => {
                            setIsDurationModified(true)
                            setDuration(0)
                        }} style={{ borderTopLeftRadius: '1em' }}>
                            <div className='tutorialBodyTab__title'>
                                <div>Formation</div>
                            </div>
                        </div>
                        <div className={quizClassTab} onClick={() => {
                            setIsDurationModified(true)
                            setDuration(20)
                        }}>
                            <div className='tutorialBodyTab__title'>
                                <div>Quiz</div>
                            </div>
                        </div>
                        <div className={formationThemeClassTab} onClick={() => {
                            setIsDurationModified(true)
                            setDuration(46)
                        }}>
                            <div className='tutorialBodyTab__title'>
                                <div>Formation (thème)</div>
                            </div>
                        </div>
                        <div className={profilClassTab} onClick={() => {
                            setIsDurationModified(true)
                            setDuration(76)
                        }}>
                            <div className='tutorialBodyTab__title'>
                                <div>Profil</div>
                            </div>
                        </div>
                        <div className={statistiquesClassTab} onClick={() => {
                            setIsDurationModified(true)
                            setDuration(108)
                        }}>
                            <div className='tutorialBodyTab__title'>
                                <div>Statistiques</div>
                            </div>
                        </div>
                        <div className={certificationClassTab} onClick={() => {
                            setIsDurationModified(true)
                            setDuration(132)
                        }}>
                            <div className='tutorialBodyTab__title'>
                                <div>Certification</div>
                            </div>
                        </div>
                    </div>
                    <div className='tutorialBodyPlayer'>
                        <div className='tutorialBodyPlayer__video'>
                            <video controls width='100%' height='100%' ref={videoRef} onTimeUpdate={(e: any) => setDuration(e.target.currentTime)}></video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

