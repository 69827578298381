import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ROUTES from '../../routes';
import httpClient from '../../services/httpClient';
import { t } from '../../services/i18n';
import store, { Message } from '../../services/store';
import './UserInformations.scss';
import DownloadIcon from '../../../assets/images/icons/download.svg';
import { useHistory } from 'react-router-dom';

const UserInformations = () => {
  const { id } = useParams<any>();
  const [userCampaigns, setUserCampaigns] = useState<any[]>([]);
  const [campaignsManager, setCampaignsManager] = useState<any[]>([]);
  const [filterCampaign, setFilterCampaign] = useState<any>('');
  const [filterCertified, setFilterCertified] = useState<any>('');
  const [filterManager, setFilterManager] = useState<any>('');
  const [user, setUser] = useState<any>({});
  const history = useHistory();

  const certifiedOrNot = [
    { id: 1, status: 'Certifié' },
    { id: 2, status: 'Non certifié' },
  ];

  useEffect(() => {
    fetchUserCampaigns();
    fetchUser();
  }, []);

  const fetchUserCampaigns = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_USER_CAMPAIGNS({ userId: id })
      );
      setUserCampaigns(res);
      let managers = res.map(
        (campaign: { campaignManager: any }) =>
          campaign.campaignManager
      );
      let uniqueManagers = [...new Set(managers)];
      let managersObject = uniqueManagers.map((manager, index) => {
        return { id: index, name: manager };
      });
      setCampaignsManager(managersObject);
    } catch (e) {
      store.notify(
        Message.Error,
        t("Impossible de récupérer les campagnes de l'utilisateur")
      );
      console.warn(e);
    }
  };

  const fetchUser = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_USER({ userId: id })
      );
      setUser(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les listes')
      );
      console.warn(e);
    }
  };

  const filterCertification = (campaign: {
    certificationValidated: boolean;
  }) => {
    if (filterCertified !== '') {
      return (
        campaign.certificationValidated ===
        (filterCertified === 'Certifié' ? true : false)
      );
    }
    return true;
  };

  const filterCampaignTitle = (campaign: { title: string }) => {
    if (filterCampaign !== '') {
      return campaign.title
        .toLowerCase()
        .includes(filterCampaign.toLowerCase());
    }
    return true;
  };

  const filterCampaignManager = (campaign: {
    campaignManager: string;
  }) => {
    if (filterManager !== '') {
      return campaign.campaignManager
        .toLowerCase()
        .includes(filterManager.toLowerCase());
    }
    return true;
  };

  const checkAll = (e: any) => {
    let checked = e.target.checked;
    let checkboxes = document.getElementsByClassName(
      'checkbox'
    ) as HTMLCollectionOf<HTMLInputElement>;
    if (checked) {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
      }
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }
  };

  const downloadCSV = async () => {
    let checkboxes = document.getElementsByClassName(
      'checkbox'
    ) as HTMLCollectionOf<HTMLInputElement>;
    let csv =
      'Nom de la campagne;Modèle de formation;Dates;Certifiés/Non certifiés;Score;Manager\n';
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        let tr = checkboxes[i].parentElement.parentElement;
        let campaignTitle =
          tr.getElementsByTagName('td')[1].innerText;
        let trainingModel =
          tr.getElementsByTagName('td')[2].innerText;
        let dates = tr.getElementsByTagName('td')[3].innerText;
        let certified = tr.getElementsByTagName('td')[4].innerText;
        let score = tr.getElementsByTagName('td')[5].innerText;
        let manager = tr.getElementsByTagName('td')[6].innerText;
        csv += `${campaignTitle};${trainingModel};${dates};${certified};${score};${manager}\n`;
      }
    }
    let BOM = "\uFEFF";
    let csvdata = BOM + csv
    let blob = new Blob([csvdata], { type: "text/csv;charset=utf-8" });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'export.csv';
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="layout2">
      <div className="container">
        <div className="gamifiedHeader">
          <div>
            {user.firstName} {user.lastName}
          </div>
          <div>Points: {user.points}</div>
        </div>
        <div className="informationsTab">
          <div className="tableHeader">
            <div className='returnBlock'>
              <div
                className='returnBlock__Button'
                onClick={() => {
                  history.push(`/users`);
                }}
              >
                <a>
                  {'Tous les utilisateurs'}
                </a>
              </div>
            </div>
            <h1>{'> ' + user.firstName + ' ' + user.lastName}</h1>
            <button
              onClick={downloadCSV}
              className="tableHeader__downloadCSVButton"
            >
              <DownloadIcon></DownloadIcon>
              <span>Télécharger CSV</span>
            </button>
          </div>
          {userCampaigns ? (
            <table className="userInformationsTable">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" onClick={checkAll} />
                  </th>
                  <th>{t('Campagne')}</th>
                  <th>{t('Modèle de formation')}</th>
                  <th>{t('Dates')}</th>
                  <th>{t('Statut')}</th>
                  <th>{t('Score')}</th>
                  <th>{t('Manager')}</th>
                </tr>
              </thead>
              <tbody>
                {userCampaigns
                  .filter(filterCampaignTitle)
                  .filter(filterCertification)
                  .filter(filterCampaignManager)
                  .map((campaign) => {
                    return (
                      <tr key={campaign.id} id="row">
                        <td>
                          <input
                            type="checkbox"
                            className="checkbox"
                          />
                        </td>
                        <td>{campaign.title}</td>
                        <td>{campaign.campaignTemplateTitle}</td>
                        <td>
                          {campaign.startDate
                            .split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}{' '}
                          au{' '}
                          {campaign.endDate
                            .split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}
                        </td>
                        <td>
                          {!campaign.certificationValidated &&
                            new Date(campaign.startDate) <=
                            new Date() &&
                            new Date() <= new Date(campaign.endDate)
                            ? 'En cours'
                            : campaign.certificationValidated
                              ? 'Certifié'
                              : 'Non certifié'}
                        </td>
                        <td>{campaign.points}</td>
                        <td>{campaign.campaignManager}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserInformations;
