import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import './Dashboard.scss';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import store, { Message } from '../../services/store';
import httpClient from '../../services/httpClient';
import ROUTES from '../../routes';
import { t } from '../../services/i18n';
import Campaign from '../../types/campaign';
import Loader from 'react-loader-spinner';
import InputWithLabel from '../../components/InputWithLabel';
import { Pie, Bar, Radar } from 'react-chartjs-2';
import { namedColor, transparentize } from './utils';
import CampaignTemplate from '../../types/campaign_template';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PenIcon from '../../../assets/images/icons/pen.svg';
import CutPenIcon from '../../../assets/images/icons/cut_pen.svg';
import CloseIcon from '../../../assets/images/icons/close.svg';
import dayjs from 'dayjs';
import * as htmlToImage from 'html-to-image';

import { IoColorFill } from "react-icons/io5"
import { AiFillSave } from "react-icons/ai"
const ResponsiveReactGridLayout = WidthProvider(Responsive);

type Item = {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  type: number;
  title: string;
  templateId?: string;
  color?: string;
};

const chartData = [
  {
    name: 'Taux de certification',
    id: 0,
  },
  {
    name: 'Taux de certification global',
    id: 1,
  },
  {
    name: 'Performance par thème',
    id: 2,
  },
  {
    name: 'Campagnes en cours (label)',
    id: 3,
  },
  {
    name: 'Taux de participation (label)',
    id: 4,
  },
  {
    name: 'Utilisateurs actifs (label)',
    id: 5,
  },
  {
    name: 'Taux de certification (label)',
    id: 6,
  },
  {
    name: 'Taux de participation campagnes par domaine',
    id: 7,
  },
  {
    name: 'Taux de participation',
    id: 8,
  }
];




const ResponsiveLocalStorageLayout = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [layouts, setLayouts] = useState<ReactGridLayout.Layouts | undefined>(
    {}
  );
  const [wasItemsFetched, setWasItemsFetched] = useState(false);
  const [wasLayoutFetched, setWasLayoutFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dropDown, setDropdown] = useState(true);
  const [colorInputShow, setColorInputShow] = useState(false);
  const [chartType, setChartType] = useState('');
  const [chartName, setChartName] = useState('');
  const [campaigns, setCampaigns] = useState<Campaign[]>(undefined);
  const [filteredCampaigns, setFilteredCampaigns] =
    useState<Campaign[]>(undefined);
  const [templates, setTemplates] = useState<CampaignTemplate[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<
    CampaignTemplate[]
  >([]);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [campaignsStats, setCampaignsStats] = useState<{
    campaignsCount: number;
    usersCount: number;
    activeUsersCount: number;
    assignedUsers: number;
    lastSevenDaysActiveUsersCount: number;
  }>(undefined);
  const [pdfView, setPdfView] = useState(false)
  const domEl = useRef(null)
  const [colorCardInput, setColorCardInput] = useState("#FFFFFF");
  const [cardFetching, setCardFetching] = useState(false);
  useEffect(() => {
    fetchDashboardItems();
    fetchDashboardLayout();
    fetchCampaigns();
    fetchTemplates();
    fetchCampaignsStats();
  }, []);

  useEffect(() => {
    fetchColorItems()
  }, [cardFetching])

  useEffect(() => {
    filterCampaignsByDate();
  }, [startDate, endDate]);

  useEffect(() => {
    if (
      campaigns == undefined ||
      templates == undefined ||
      campaignsStats == undefined
    ) {
      return;
    }
    setLoading(!campaigns);
    setFilteredCampaigns(campaigns);
    removeUnusedTemplates();
  }, [campaigns, templates]);

  const fetchColorItems = () => {
    items.map((item, index) => {
      if (item.color) {
        const card = document.getElementById(`card-${item.i}`);
        card.style.backgroundColor = item.color
      }
    })
  }

  const fetchDashboardLayout = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_DASHBOARD_LAYOUT({
          userId: store.store.JWT?.sub,
        })
      );
      if (res) setLayouts(res);
      setWasLayoutFetched(true);
      return res;
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer la disposition de votre tableau de bord')
      );
      console.warn(e);
    }
  };

  const downloadImage = async () => {
    const dataUrl = await htmlToImage.toPng(domEl.current);
    // download image
    const link = document.createElement('a');
    link.download = "dashboard.png";
    link.href = dataUrl;
    link.click();
  }

  const fetchDashboardItems = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_DASHBOARD_ITEMS({
          userId: store.store.JWT?.sub,
        })
      );
      if (res) setItems(res);
      setWasItemsFetched(true);
      return res;
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les données de votre tableau de bord')
      );
      console.warn(e);
    }
  };

  const fetchCampaigns = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_CAMPAIGNS({ all: false }));
      setCampaigns(res);
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les formations'));
      console.warn(e);
    }
  };

  const fetchTemplates = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_CAMPAIGN_TEMPLATES());
      setTemplates(res);
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les formations'));
      console.warn(e);
    }
  };

  const fetchCampaignsStats = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_CAMPAIGNS_STATS());
      setCampaignsStats(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les statistiques des formations')
      );
      console.warn(e);
    }
  };

  const commitDashboardLayout = async (dashboardLayouts: any) => {
    try {
      await httpClient.req(
        items.length > 0
          ? ROUTES.UPDATE_DASHBOARD_LAYOUT({
            userId: store.store.JWT?.sub,
            dashboard_layout: dashboardLayouts,
          })
          : ROUTES.CREATE_DASHBOARD_LAYOUT({
            userId: store.store.JWT?.sub,
            dashboard_layout: dashboardLayouts,
          })
      );
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de sauvegarder le tableau de bord')
      );
      console.warn(e);
    }
  };

  const commitDashboardItems = async (dashboardItems: any) => {
    try {
      await httpClient.req(
        items.length > 0
          ? ROUTES.UPDATE_DASHBOARD_ITEMS({
            userId: store.store.JWT?.sub,
            dashboard_items: dashboardItems,
          })
          : ROUTES.CREATE_DASHBOARD_ITEMS({
            userId: store.store.JWT?.sub,
            dashboard_items: dashboardItems,
          })
      );
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de sauvegarder le tableau de bord')
      );
      console.warn(e);
    }
  };

  const removeUnusedTemplates = () => {
    const templatesToKeep = templates.filter((template) =>
      campaigns.find((campaign) => campaign.campaignTemplateId === template.id)
    );
    setFilteredTemplates(templatesToKeep);
  };

  const filterCampaignsByDate = () => {
    if (startDate == null && endDate == null) {
      setFilteredCampaigns(campaigns);
      return;
    }
    if (startDate == null) {
      setFilteredCampaigns(
        campaigns.filter(
          (campaign) =>
            new Date(campaign.endDate).getTime() <= new Date(endDate).getTime()
        )
      );
      return;
    }
    if (endDate == null) {
      setFilteredCampaigns(
        campaigns.filter(
          (campaign) =>
            new Date(campaign.startDate).getTime() >=
            new Date(startDate).getTime()
        )
      );
      return;
    }
    setFilteredCampaigns(
      campaigns.filter(
        (campaign) =>
          new Date(campaign.startDate).getDate() >=
          new Date(startDate).getDate() &&
          new Date(campaign.endDate).getDate() <= new Date(endDate).getDate()
      )
    );
  };

  const addItem = () => {
    const newItem: Item = {
      i: items.length.toString(),
      x: 1,
      y: 0,
      w: parseInt(chartType) < 3 ? 2 : 1,
      h: parseInt(chartType) < 3 ? 2 : 1,
      type: parseInt(chartType),
      title: chartName,
      templateId: selectedTemplate,
    };
    setItems((oldItems) => [...oldItems, newItem]);
    commitDashboardItems([...items, newItem]);
    showDropdown();
  };

  const removeItem = (id: string) => {
    const newItems = items.filter((elem) => elem.i !== id);
    setItems(newItems);
    commitDashboardItems(newItems);
  };

  const createCertificationRate = (elem: Item) => {
    return (
      <Bar
        data={{
          labels: ['Taux de certification par campagne (en %)'],
          datasets: filteredCampaigns.map((campaign, index) => ({
            label: campaign.title,
            data: [
              Math.floor(
                (campaign.stats.certificationValidatedCount * 100) /
                campaign.stats.usersCount
              ),
            ],
            backgroundColor: namedColor(index),
          })),
        }}
        options={{
          title: {
            display: true,
            text: elem.title,
          },
          legend: {
            display: true,
            position: 'right' as const,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 100,
                  beginAtZero: true,
                },
              },
            ],
          },
          layout: {
            padding: {
              bottom: 20,
              left: 10,
            },
          },
          maintainAspectRatio: false,
        }}
      />
    );
  };

  const createGlobalCertificationRate = (elem: Item) => {
    let avg: number = 0;
    filteredCampaigns.forEach((campaign) => {
      avg += Math.floor(
        (campaign.stats.certificationValidatedCount * 100) /
        campaign.stats.usersCount
      );
    });
    avg = avg / filteredCampaigns.length;

    return (
      <Pie
        data={{
          labels: ['Taux moyen de certifiés', 'Taux moyen de non certifiés'],
          datasets: [
            {
              label: '# of Votes',
              data: [avg, 100 - avg],
              backgroundColor: ['rgb(180, 204, 42)', 'rgb(240, 106, 47)'],
              borderWidth: 1,
            },
          ],
        }}
        options={{
          title: {
            display: true,
            text: elem.title,
          },
          legend: {
            display: true,
            position: 'right' as const,
          },
          layout: {
            padding: {
              bottom: 20,
              left: 10,
            },
          },
          maintainAspectRatio: false,
        }}
      />
    );
  };

  const createThemePerformance = (elem: Item) => {
    const themes = filteredCampaigns.filter(
      (campaign) => campaign.campaignTemplateId === elem.templateId
    );
    if (themes.length === 0) {
      return null;
    }
    return (
      <Radar
        data={{
          labels: themes[0].stats.themePerformance.map((theme) => theme.name),
          datasets: themes.map((theme, index) => ({
            label: theme.title,
            data: theme.stats.themePerformance.map(
              (stat) => stat.performance * 100
            ),
            backgroundColor: transparentize(namedColor(index), 0.6),
          })),
        }}
        options={{
          title: {
            display: true,
            text: elem.title,
          },
          legend: {
            display: true,
            position: 'right' as const,
          },
          scale: {
            ticks: {
              min: 0,
              max: 100,
              beginAtZero: true,
            },
          },
          layout: {
            padding: {
              bottom: 20,
              left: 10,
            },
          },
          maintainAspectRatio: false,
        }}
      />
    );
  };

  const createCurrentCampaignsLabel = (elem: Item) => {
    return (
      <div className="labelsContainer">
        <div>
          {
            filteredCampaigns.filter(
              (c) =>
                dayjs(c.endDate).isAfter(dayjs()) &&
                dayjs(c.startDate).isBefore(dayjs())
            ).length
          }
        </div>
        <div>Campagnes en cours</div>
      </div>
    );
  };

  const createParticipationRateLabel = (elem: Item) => {
    return (
      <div className="labelsContainer">
        <div>
          {Math.round(
            (campaignsStats.activeUsersCount / campaignsStats.usersCount) * 100
          )}
          %
        </div>
        <div>Taux moyen de participation</div>
      </div>
    );
  };

  const createActiveUsersLabel = (elem: Item) => {
    return (
      <div className="labelsContainer">
        <div>{campaignsStats.activeUsersCount}</div>
        <div>Utilisateurs actifs</div>
      </div>
    );
  };

  const createCertificationRateLabel = (elem: Item) => {
    let avg: number = 0;
    filteredCampaigns.forEach((campaign) => {
      avg += Math.floor(
        (campaign.stats.certificationValidatedCount * 100) /
        campaign.stats.usersCount
      );
    });
    avg = avg / filteredCampaigns.length;
    return (
      <div className="labelsContainer">
        <div>{avg}%</div>
        <div>D'utilisateurs certifiés</div>
      </div>
    );
  };

  const createParticipationRatePerCampaign = (elem: Item) => {
    const campaignsPerSelectedTheme = filteredCampaigns.filter(
      (campaign) => campaign.campaignTemplateId === elem.templateId
    );
    return (
      <Bar
        data={{
          labels: ['Taux de participation par campagne du même domaine (en %)'],
          datasets: campaignsPerSelectedTheme.map((campaign, index) => ({
            label: campaign.title,
            data: [
              Math.floor(
                (campaign.stats.activeUsersCount / campaign.stats.usersCount) *
                100
              ),
            ],
            backgroundColor: namedColor(index),
          })),
        }}
        options={{
          title: {
            display: true,
            text: elem.title,
          },
          legend: {
            display: true,
            position: 'right' as const,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 100,
                  beginAtZero: true,
                },
              },
            ],
          },
          layout: {
            padding: {
              bottom: 20,
              left: 10,
            },
          },
          maintainAspectRatio: false,
        }}
      />
    );
  };

  const createParticipationRate = (elem: Item) => {
    const rate =
      Math.round(
        (campaignsStats.activeUsersCount / campaignsStats.usersCount) * 100
      )
    return (
      <Pie
        data={{
          labels: ['Taux de participants', 'Taux d\'absents'],
          datasets: [
            {
              label: '# of Votes',
              data: [rate, 100 - rate],
              backgroundColor: ['rgb(180, 204, 42)', 'rgb(240, 106, 47)'],
              borderWidth: 1,
            },
          ],
        }}
        options={{
          title: {
            display: true,
            text: elem.title,
          },
          legend: {
            display: true,
            position: 'right' as const,
          },
          layout: {
            padding: {
              bottom: 20,
              left: 10,
            },
          },
          maintainAspectRatio: false,
        }}
      />
    );
  }

  const createChart = (elem: Item) => {
    switch (elem.type) {
      case 0:
        return createCertificationRate(elem);
      case 1:
        return createGlobalCertificationRate(elem);
      case 2:
        return createThemePerformance(elem);
      case 3:
        return createCurrentCampaignsLabel(elem);
      case 4:
        return createParticipationRateLabel(elem);
      case 5:
        return createActiveUsersLabel(elem);
      case 6:
        return createCertificationRateLabel(elem);
      case 7:
        return createParticipationRatePerCampaign(elem);
      case 8:
        return createParticipationRate(elem);
      default:
        return <div></div>;
    }
  };

  const commitColorDashboardItems = async (key: string) => {
    const card = document.getElementById(`card-${key}`);
    let itemsUpdated = items
    itemsUpdated.map((item, index) => {
      if (item.i === key)
        item.color = card.style.backgroundColor
    })
    setItems(itemsUpdated)
    commitDashboardItems(itemsUpdated)
  };

  const showColorInput = (key: string) => {
    setColorInputShow(!colorInputShow);
    const colorInput = document.getElementById(key);
    if (colorInputShow) {
      colorInput.style.width = '8em';
      colorInput.style.height = '1.5em';
    } else {
      colorInput.style.width = '0';
      colorInput.style.height = '0';
    }
  };

  const changeColorCard = (key: string) => {
    const card = document.getElementById(`card-${key}`);
    card.style.backgroundColor = colorCardInput
  };

  const createElement = (elem: Item, id: number) => {
    const key = elem.i;

    return (
      <div key={key} id={`card-${key}`} data-index={key} className="card" data-grid={elem} >
        {
          editMode ? (
            <div>
              <button
                type="button"
                className="remove-card"
                onClick={(e) => removeItem(key)}
              >
                <CloseIcon />
              </button>
              <button
                type="button"
                className="color-card"
                onClick={(e) =>
                  showColorInput(key)
                }
              >
                <IoColorFill style={{ fontSize: "1.5em" }} />
              </button>
              <input type="text" id={key} className='colorChanger' value={colorCardInput} onChange={(e) => {
                if (e.target.value.length < 8 && e.target.value.length != 0) {
                  setColorCardInput(e.target.value)
                  changeColorCard(key)
                }
              }} />
              <button
                type='button'
                className='buttonValidateColor'
                onClick={() => {
                  commitColorDashboardItems(key);
                }}>
                <AiFillSave style={{ fontSize: "2em" }} />
              </button>
            </div >
          ) : null}
        {/* {key} */}
        {
          loading ? (
            <Loader
              type="ThreeDots"
              color="#62a5e2"
              height={100}
              width={100}
              className="loader"
            />
          ) : (
            createChart(elem)
          )
        }
      </div >
    );
  };

  const onLayoutChange = (dashboardLayout: any, dashboardLayouts: any) => {
    if (wasLayoutFetched && wasItemsFetched) {
      commitDashboardLayout(dashboardLayouts);
      setLayouts(dashboardLayouts);
    }
  };

  const showHeader = () => {
    setEditMode(!editMode);
    if (!editMode) {
      document.getElementById('header').style.display = 'flex';
      document.getElementById('edit').style.visibility = 'hidden';
      document.getElementById('stopEdit').style.visibility = 'visible';
    } else {
      document.getElementById('header').style.display = 'none';
      document.getElementById('edit').style.visibility = 'visible';
      document.getElementById('stopEdit').style.visibility = 'hidden';
    }
  };

  const clickOutsideElement = (callback: any) => {
    const callbackRef = useRef<any>();
    const innerRef = useRef<any>();

    useEffect(() => {
      callbackRef.current = callback;
    });
    useEffect(() => {
      document.addEventListener('click', handleClick);
      return () => document.removeEventListener('click', handleClick);
      function handleClick(event: any) {
        if (
          innerRef.current &&
          callbackRef.current &&
          !innerRef.current.contains(event.target)
        ) {
          callbackRef.current(event);
        }
      }
    }, []);
    return innerRef;
  };

  const showDropdown = () => {
    setDropdown(!dropDown);
    const addBtn = document.getElementById('addBtn');
    if (!dropDown) {
      addBtn.style.borderRadius = '6px 6px 0 0';
    } else {
      addBtn.style.borderRadius = '6px';
    }
  };

  const addWidgetRef = clickOutsideElement(() => {
    if (dropDown) showDropdown();
  });

  return (
    <div id="domEl" ref={domEl} className="layout">
      <div className="edit-container">
        <div className='pdfButton' onClick={() => {
          downloadImage()
        }}>{t("Télécharger")}</div>
        <button className="edit">
          <PenIcon id="edit" className="edit__icon" onClick={showHeader} />
          <CutPenIcon
            id="stopEdit"
            className="edit__cancel"
            onClick={() => {
              showHeader(),
                setColorInputShow(false)
            }}
          />
        </button>
      </div><div id="header" className="header">
        <div>
          <button
            id="addBtn"
            className="dropbtn"
            onClick={() => showDropdown()}
          >
            + Add widget
          </button>
          {dropDown ? (
            <div id="dropdown" className="dropdownContent" ref={addWidgetRef}>
              <div className="inputNameContainer">
                <p>Name:</p>
                <div className="nameInputContainer">
                  <InputWithLabel
                    value={chartName}
                    type="text"
                    onChange={(v) => setChartName(v)} />
                </div>
              </div>
              <div className="typeSelectorContainer">
                <p>Type:</p>
                <div className="typeInputContainer">
                  <InputWithLabel
                    value={chartType}
                    onChange={(v) => {
                      setChartType(v);
                    }}
                    selectOptions={chartData}
                    selectLabelKey={'name'}
                    selectValueKey={'id'} />
                </div>
              </div>
              {chartType === '2' || chartType == '7' ? (
                <div className="themeSelectorContainer">
                  <p>Theme:</p>
                  <div className="themeInputContainer">
                    <InputWithLabel
                      value={selectedTemplate}
                      onChange={(v) => {
                        setSelectedTemplate(v);
                      }}
                      selectOptions={filteredTemplates}
                      selectLabelKey={'title'}
                      selectValueKey={'id'} />
                  </div>
                </div>
              ) : null}
              {(chartType === '2' || chartType == '7') && !selectedTemplate ? (
                <div className="widgetButtonContainer">
                  <button
                    disabled
                    className="addItemDisabled"
                    onClick={addItem}
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <div className="widgetButtonContainer">
                  <button className="addItem" onClick={addItem}>
                    Submit
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="Start date" />
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText="End date" />
      </div><div className="tabsContainer">
        {items.length === 0 && editMode === false ? (
          <div className="addWidgetContainer">
            <div
              className="Home__campaignsAdd Home__campaignsCard"
              onClick={showHeader}
            >
              <div className="Home__campaignsAddIcon">
                <i>add</i>
              </div>
              <div className="Home__campaignsAddTitle">
                {t('Ajouter un widget')}
              </div>
            </div>
          </div>
        ) : null}
        {wasItemsFetched && wasLayoutFetched ? (
          <ResponsiveReactGridLayout
            cols={{ lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 }}
            containerPadding={[0, 0]}
            onLayoutChange={(dashboardLayout, dashboardLayouts) => onLayoutChange(dashboardLayout, dashboardLayouts)}
            layouts={layouts}
          >
            {items.map((elem, id) => createElement(elem, id))}
            {cardFetching ? null : setCardFetching(true)}
          </ResponsiveReactGridLayout>
        ) : null}
      </div>
    </div >
  );
};

export default ResponsiveLocalStorageLayout;
