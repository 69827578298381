import React, { useContext, useEffect, useState } from 'react';
import './CampaignCreationRecap.scss';
import Campaign from '../../../types/campaign';
import { t } from '../../../services/i18n';
import Button from '../../../components/Button';
import store, { Message } from '../../../services/store';
import httpClient from '../../../services/httpClient';
import ROUTES from '../../../routes';
import { useHistory } from 'react-router-dom';
import CampaignTemplate from '../../../types/campaign_template';
import dayjs from 'dayjs';
import User from '../../../types/user';
import { StepperContext } from 'react-material-stepper';
import Modal from '../../../components/Modal';

interface Props {
  campaign: Campaign;
}

export default (props: Props) => {
  const { getData } = useContext(StepperContext);
  const { campaign }: { campaign: Campaign } =
    getData('schedule') || props;
  const template: CampaignTemplate = campaign.campaignTemplate;
  const users: User[] = campaign.users;
  const history = useHistory();
  const [displayModal, setDisplayModal] = useState(false);
  const [alreadySubscribed, setAlreadySubscribed] = useState([]);
  const [campaignUsers, setCampaignUsers] = useState<User[]>([]);

  useEffect(() => {
    if (users.length != 0)
      fetchUsersCampaigns();
    setCampaignUsers(users);
  }, []);

  const fetchUsersCampaigns = async () => {
    try {
      console.log(users)
      let res = await httpClient.req(
        ROUTES.FETCH_USERS_CAMPAIGNS({
          usersIds: users.map((user) => user.id),
        })
      );
      let tempAlreadySubscribed = getAlreadySubscribedUsers(res);
      let alreadySubscribed = tempAlreadySubscribed.filter(
        (user) => !campaignUsers.find((u) => u.id === user.id)
      );
      setAlreadySubscribed(alreadySubscribed);
      if (tempAlreadySubscribed.length > 0) {
        setDisplayModal(true);
      }
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer campagnes des utilisateurs')
      );
      console.warn(e);
    }
  };

  const commitCampaign = async () => {
    try {
      campaign.users = campaignUsers;
      await httpClient.req(
        (campaign.id
          ? ROUTES.UPDATE_CAMPAIGN
          : ROUTES.CREATE_CAMPAIGN)({
            campaign,
          })
      );
      history.push('/');
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de sauvegarder la formation')
      );
      console.warn(e);
    }
  };

  const getAlreadySubscribedUsers = (usersCampaigns: any) => {
    let alreadySubscribed: any[] = [];
    usersCampaigns.forEach(
      (userCampaign: { campaigns: any[]; email: any }) => {
        userCampaign.campaigns.forEach((campaign) => {
          if (campaign.campaignTemplateId === template.id) {
            alreadySubscribed.push(userCampaign.email);
          }
        });
      }
    );
    return alreadySubscribed;
  };

  const removeAlreadySubscribedUsers = (alreadySubscribed: any) => {
    let usersToRemove: any[] = [];
    campaignUsers.forEach((user) => {
      if (alreadySubscribed.includes(user.email)) {
        usersToRemove.push(user);
      }
    });
    setCampaignUsers(
      campaignUsers.filter((user) => !usersToRemove.includes(user))
    );
    setDisplayModal(false);
  };

  return (
    <div className="CampaignCreationRecap">
      {displayModal && (
        <Modal dismiss={() => setDisplayModal(false)}>
          <div className="modalContent">
            <div className="modalTitle">
              Utilisateurs déjà certifiés ou en cours dans ce modèle
              de formation
            </div>
            <div className="modalSubscribed">
              {alreadySubscribed.map((user, index) => (
                <div key={index}>{user}</div>
              ))}
            </div>
            <div className="buttonTitle">
              Action à réaliser sur l'ensemble des utilisateurs
              ci-dessus
            </div>
            <div className="buttonsDiv">
              <button
                className="deleteButton"
                onClick={() =>
                  removeAlreadySubscribedUsers(alreadySubscribed)
                }
              >
                Supprimer
              </button>
              <button
                className="okButton"
                onClick={() => setDisplayModal(false)}
              >
                Continuer
              </button>
            </div>
          </div>
        </Modal>
      )}
      <div className="section__title">{t('Récapitulatif')}</div>
      <div className="section__body">
        <div className="CampaignCreationRecap__title">
          {t('Nom de la formation')}
        </div>
        <div>{campaign.title}</div>
        <div className="CampaignCreationRecap__title">
          {t('Type de formation')}
        </div>
        <div>
          {campaign.oneShot ? t('Questionnaire') : t('formation')}
        </div>
        <div className="CampaignCreationRecap__title">
          {t('Description')}
        </div>
        <div>{campaign.description}</div>
        <div className="CampaignCreationRecap__title">
          {t('Modèle de formation')}
        </div>
        <div>{template?.title}</div>
        <div className="CampaignCreationRecap__title">
          {t('Durée de la formation')}
        </div>
        <div>
          {dayjs(campaign.endDate).diff(campaign.startDate, 'week') >
            0
            ? t('%weeks semaine(s)', {
              weeks: dayjs(campaign.endDate).diff(
                campaign.startDate,
                'week'
              ),
            })
            : t('%days jour(s)', {
              days: dayjs(campaign.endDate).diff(
                campaign.startDate,
                'day'
              ),
            })}
        </div>
        <div className="CampaignCreationRecap__title">
          {t("Nombre d'utilisateurs")}
        </div>
        <div>{campaignUsers.length}</div>
        <div className="CampaignCreationRecap__title">
          {t('Date de début')}
        </div>
        <div>{dayjs(campaign.startDate).format('LLLL')}</div>
        <div className="CampaignCreationRecap__title">
          {t('Date de fin')}
        </div>
        <div>{dayjs(campaign.endDate).format('LLLL')}</div>
        <div className="CampaignCreationRecap__title">
          {t('Email de lancement')}
        </div>
        <div>
          {campaign.manualEmail
            ? t('Email envoyé par mes soins')
            : t('Email automatique Vigiware')}
        </div>
        <div className="section__actions">
          <Button title={t('Sauvegarder')} onClick={commitCampaign} />
        </div>
      </div>
    </div>
  );
};
