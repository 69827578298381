import React from 'react'
import './Button.scss'

interface Props {
  title: string
  onClick: () => void
  disabled?: boolean
  secondaryColor?: boolean
  dangerColor?: boolean
  className?: string
}

export default (props: Props) => {
  return (
    <div
      className={`Button${props.className ? ` ${props.className}` : ''}${
        props.disabled ? ' Button--disabled' : ''
      }${
        props.dangerColor
          ? ' Button--dangerColor'
          : props.secondaryColor
          ? ' Button--secondaryColor'
          : ''
      }`}
      onClick={props.disabled ? undefined : props.onClick}
    >
      {props.title}
    </div>
  )
}
