import React, { useState, Fragment, useEffect } from "react";
import Logo from "../../../../assets/images/logo.png";
import IconHome from "../../../../assets/images/icons/home.svg";
import IconTrainingModels from "../../../../assets/images/icons/book.svg";
import IconUsers from "../../../../assets/images/icons/users.svg";
import IconDashboard from "../../../../assets/images/icons/dashboard.svg";
import "./LayoutMenu.scss";
import store, { Message } from "../../../services/store";
import { t } from "../../../services/i18n";
import qs from "qs";
import { useForceUpdate } from "../../../utils";
import Button from "../../../components/Button";
import { SpotlightTour, useSpotlight } from "react-spotlight-tour";
import Spotlight from "react-spotlight-tour/spotlight";
import { TbLamp } from "react-icons/tb";
import ReactTooltip from "react-tooltip";
import httpClient from "../../../services/httpClient";
import ROUTES from "../../../routes";

interface tuto {
  active?: boolean;
}

function Tutorial({ active }: tuto) {
  const spotlightRef = useSpotlight("Click to see the tutorial", "right");

  return active ? (
    <a
      href="/tutorial"
      className="didactitielButton"
      data-tip={t("Didactitiel")}
      ref={spotlightRef}
    >
      <TbLamp style={{ height: "1.5em", width: "1.5em" }}></TbLamp>
    </a>
  ) : (
    <a
      href="/tutorial"
      className="didactitielButton"
      data-tip={t("Didactitiel")}
    >
      <TbLamp style={{ height: "1.5em", width: "1.5em" }}></TbLamp>
    </a>
  );
}

export default () => {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const forceUpdate = useForceUpdate();
  const [isOpen, setOpen] = useState(true);
  const [isTutoActive, setIsTutoActive] = useState(false);
  const [user, setUser] = useState(undefined);
  let inSession: boolean = undefined;

  store.listen(Message.PreviousContent, forceUpdate);
  store.listen(Message.NextContent, forceUpdate);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await fetchUser();
  };

  const fetchUser = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_ME());
      setUser(res);
      if (res.signInCount <= 2) {
        setIsTutoActive(true);
      }
    } catch (e) {
      store.notify(Message.Error, "Impossible de récupérer le profil");
      console.warn(e);
    }
  };

  return (
    <SpotlightTour
      open={isOpen}
      onClose={() => setOpen(false)}
      Spotlight={Spotlight}
    >
      <Fragment>
        <div className="Layout__menu">
          <div className="Layout__menuTop">
            <div className="Layout__menuTopHeader">
              <a href="/">
                {store.store.JWT.license_logo_url ? (
                  <img
                    src={store.store.JWT.license_logo_url}
                    className="Layout__menuLogo"
                  />
                ) : (
                  <img src={Logo} className="Layout__menuLogo" />
                )}
              </a>
              <div className="didactitiel">
                <ReactTooltip place="bottom" type="dark" effect="solid" />
                {isTutoActive ? (
                  <Tutorial active={true} />
                ) : (
                  <Tutorial active={false} />
                )}
              </div>
            </div>
            <nav>
              <a href="/" className="Layout__menuEntry">
                <IconHome /> {t("Accueil")}
              </a>
              {["manager", "admin", "superadmin", "top_manager"].includes(
                store.store.JWT.role
              ) && (
                <Fragment>
                  <a href="/campaign_templates" className="Layout__menuEntry">
                    <IconTrainingModels /> {t("Modèles de formation")}
                  </a>
                  <a href="/users" className="Layout__menuEntry">
                    <IconUsers /> {t("Utilisateurs")}
                  </a>
                  {["admin", "superadmin"].includes(store.store.JWT.role) && (
                    <Fragment>
                      <a href="/dashboard" className="Layout__menuEntry">
                        <IconDashboard /> {t("Tableau de bord")}
                      </a>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </nav>
          </div>
          <div className="Layout__menuBottom">
            <a href="/my_account">
              <Button title={t("Mon compte")} onClick={undefined} />
            </a>
            <Button
              title={t("Déconnexion")}
              dangerColor
              onClick={() => store.notify(Message.NeedAuth)}
            />
          </div>
        </div>
        <div
          className={`Layout__menuMobile${
            menuExpanded ? " Layout__menuMobile--expanded" : ""
          }`}
        >
          <div className="Layout__menuMobileHeader">
            {inSession ? (
              qs.parse(location.search.replace("?", "")).idx == "0" ? (
                <div></div>
              ) : (
                <div
                  className="Layout__menuMobilePrevious"
                  onClick={() => store.notify(Message.PreviousContent)}
                >
                  <i>keyboard_arrow_left</i>
                  {t("Précédent")}
                </div>
              )
            ) : (
              <div
                className="Layout__menuMobileToggle"
                onClick={() => setMenuExpanded(!menuExpanded)}
              >
                <i>menu</i>
              </div>
            )}
            <a href="/">
              {store.store.JWT.license_logo_url ? (
                <img
                  src={store.store.JWT.license_logo_url}
                  className="Layout__menuLogo"
                />
              ) : (
                <img src={Logo} className="Layout__menuLogo" />
              )}
            </a>
            {inSession ? (
              <div
                className="Layout__menuMobileQuit"
                onClick={() => store.notify(Message.QuitSession)}
              >
                {t("Quitter")}
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {menuExpanded && (
            <div className="Layout__menuMobileContent">
              <nav>
                <a href="/" className="Layout__menuEntry">
                  <IconHome /> {t("Accueil")}
                </a>
                <a href="/campaign_templates" className="Layout__menuEntry">
                  <IconHome /> {t("Modèles de formation")}
                </a>
                <a href="/users" className="Layout__menuEntry">
                  <IconHome /> {t("Utilisateurs")}
                </a>
                <a href="/dashboard" className="Layout__menuEntry">
                  <IconHome /> {t("Tableau de bord")}
                </a>
              </nav>
            </div>
          )}
        </div>
      </Fragment>
    </SpotlightTour>
  );
};
