import React, { useState } from 'react'
import './UserForm.scss'
import User from '../../types/user'
import InputWithLabel from '../../components/InputWithLabel'
import { t } from '../../services/i18n'
import Button from '../../components/Button'
import store from '../../services/store'

interface Props {
  user?: User
  onSubmit?: (user: User) => void
}

export default (props: Props) => {
  const [user, setUser] = useState<User>(
    props.user ||
    ({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      role: 'user',
    } as User)
  )

  const roles = () => {
    const res = [
      { value: 'user', label: t('Apprenant') },
      { value: 'correspondant', label: t('Correspondant') },
    ]

    if (['top_manager', 'admin', 'superadmin'].includes(store.store.JWT.role))
      res.push(
        { value: 'manager', label: t('Manager') },
        { value: 'top_manager', label: t('Top manager') }
      )
    return res
  }


  return (
    <div className="UserForm">
      <InputWithLabel
        label={t('Prénom')}
        value={user.firstName}
        onChange={(v) => setUser({ ...user, firstName: v })}
      />
      <InputWithLabel
        label={t('Nom')}
        value={user.lastName}
        onChange={(v) => setUser({ ...user, lastName: v })}
      />
      <InputWithLabel
        label={t('Courriel')}
        value={user.email}
        onChange={(v) => setUser({ ...user, email: v })}
      />
      <InputWithLabel
        label={t('Rôle')}
        value={user.role}
        selectOptions={roles()}
        selectLabelKey="label"
        selectValueKey="value"
        onChange={(v) => setUser({ ...user, role: v })}
      />
      <InputWithLabel
        label={t('Mot de passe')}
        value={user.password}
        type="password"
        onChange={(v) => setUser({ ...user, password: v })}
      />
      <InputWithLabel
        label={t('Confirmation du mot de passe')}
        value={user.passwordConfirmation}
        type="password"
        onChange={(v) => setUser({ ...user, passwordConfirmation: v })}
      />
      {user.password != user.passwordConfirmation && (
        <div className="UserForm__error">
          {t('Les mots de passe de correspondent pas')}
        </div>
      )}
      <InputWithLabel
        label={t('Téléphone portable')}
        value={user.phone}
        type="text"
        onChange={(v) => setUser({ ...user, phone: v })}
      />
      <InputWithLabel
        label={t('Fonction')}
        value={user.job}
        type="text"
        onChange={(v) => setUser({ ...user, job: v })}
      />
      <InputWithLabel
        label={t('Filiale')}
        value={user.affiliate}
        type="text"
        onChange={(v) => setUser({ ...user, affiliate: v })}
      />
      <InputWithLabel
        label={t('Service')}
        value={user.service}
        type="text"
        onChange={(v) => setUser({ ...user, service: v })}
      />
      <InputWithLabel
        label={t('Manager direct')}
        value={user.directManager}
        type="text"
        onChange={(v) => setUser({ ...user, directManager: v })}
      />
      <InputWithLabel
        label={t('Région 1')}
        value={user.regionOne}
        type="text"
        onChange={(v) => setUser({ ...user, regionOne: v })}
      />
      <InputWithLabel
        label={t('Région 2')}
        value={user.regionTwo}
        type="text"
        onChange={(v) => setUser({ ...user, regionTwo: v })}
      />
      <InputWithLabel
        label={t('Champ libre 1')}
        value={user.fieldOne}
        type="text"
        onChange={(v) => setUser({ ...user, fieldOne: v })}
      />
      <InputWithLabel
        label={t('Champ libre 2')}
        value={user.fieldTwo}
        type="text"
        onChange={(v) => setUser({ ...user, fieldTwo: v })}
      />
      <Button
        title={t('Ajouter')}
        onClick={props.onSubmit && (() => props.onSubmit(user as any))}
      />
    </div>
  )
}
