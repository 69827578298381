import React, { useContext, useEffect, useState, Fragment } from 'react'
import './CampaignCreationTemplate.scss'
import InputWithLabel from '../../../components/InputWithLabel'
import Campaign from '../../../types/campaign'
import { t } from '../../../services/i18n'
import Button from '../../../components/Button'
import { StepperContext } from 'react-material-stepper'
import store, { Message } from '../../../services/store'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import CampaignTemplate from '../../../types/campaign_template'
import { useQuery } from '../../../utils'

interface Props {
  campaign: Campaign
}

export default (props: Props) => {
  const { resolve, getData } = useContext(StepperContext)
  const [templates, setTemplates] = useState<CampaignTemplate[]>([])
  const [templateLoaded, setTemplateLoaded] = useState(false)
  const query = useQuery()
  const [campaign, setCampaign] = useState<Campaign>(
    query.get('templateId')
      ? {
          ...(getData('template')?.campaign ||
            getData('title')?.campaign ||
            props.campaign),
          campaignTemplateId: query.get('templateId'),
        }
      : getData('template')?.campaign ||
          getData('title')?.campaign ||
          props.campaign
  )

  useEffect(() => {
    fetchTemplates()
  }, [])

  useEffect(() => {
    if (campaign.certificationTriesPerPeriod < 1) {
      setCampaign({ ...campaign, certificationTriesPerPeriod: 1 })
    }
  }, [campaign])

  const fetchTemplates = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_CAMPAIGN_TEMPLATES())

      setTemplates(res)
      setTemplateLoaded(true)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les formations'))
      console.warn(e)
    }
  }

  const goToNextStep = () => {
    resolve({
      campaign: {
        ...campaign,
        campaignTemplate: templates.find(
          (ct) => ct.id == campaign.campaignTemplateId
        ),
      },
    })
  }

  return (
    <div className="CampaignCreationTemplate">
      <div className="section__title">{t('Paramètres de la campagne')}</div>
      <div className="section__body">
        <InputWithLabel
          label={t('Modèle de formation')}
          selectOptions={templates}
          selectLabelKey={'title'}
          selectValueKey={'id'}
          value={campaign.campaignTemplateId}
          onChange={(v) =>
            setCampaign({
              ...campaign,
              campaignTemplateId: v,
              certificationUnlockThreshold: templates.find((t) => t.id == v)
                .certificationUnlockThreshold,
            })
          }
          disabled={!!props.campaign.id}
        />
        {!campaign.oneShot && (
          <Fragment>
            <InputWithLabel
              label={t('Palier de déblocage de la certification (en %)')}
              value={campaign.certificationUnlockThreshold?.toString()}
              type="number"
              onChange={(v) =>
                setCampaign({ ...campaign, certificationUnlockThreshold: +v })
              }
            />
            <div className="CampaignCreationTemplate__certificationPeriod">
              <InputWithLabel
                label={t("Nombre max. d'erreurs pour valider la certification")}
                value={campaign.certificationMaxErrors?.toString()}
                type="number"
                onChange={(v) =>
                  setCampaign({ ...campaign, certificationMaxErrors: +v })
                }
              />
              <span>{t(' sur ')}</span>
              <InputWithLabel
                label={t('Nombre de question pour la certification')}
                value={campaign.certificationQuestionsCount?.toString()}
                type="number"
                onChange={(v) =>
                  setCampaign({ ...campaign, certificationQuestionsCount: +v })
                }
              />
            </div>
            <div className="CampaignCreationTemplate__certificationPeriod">
              <InputWithLabel
                label={t('Nombre de tentatives de certification')}
                value={campaign.certificationTriesPerPeriod?.toString()}
                type="number"
                min="1"
                onChange={(v) =>
                  setCampaign({
                    ...campaign,
                    certificationTriesPerPeriod: +v,
                  })
                }
              />
              <span>{t('essai(s) par')}</span>
              <InputWithLabel
                value={campaign.certificationPeriod?.toString()}
                selectOptions={[
                  { label: t('Jour'), value: 'day' },
                  { label: t('Semaine'), value: 'week' },
                  { label: t('Mois'), value: 'month' },
                ]}
                selectLabelKey="label"
                selectValueKey="value"
                type="number"
                onChange={(v) =>
                  setCampaign({ ...campaign, certificationPeriod: v })
                }
                disableSorting
              />
            </div>
          </Fragment>
        )}
        <div className="section__actions">
          <Button
            title={t('Suivant')}
            onClick={goToNextStep}
            disabled={!templateLoaded || !campaign.campaignTemplateId}
          />
        </div>
      </div>
    </div>
  )
}
