export default {
    "Créer une formation": {
        fr: "Créer une formation",
        en: "Create a formation"
    },
    "Durée totale des sessions : ": {
        fr: "Durée totale des sessions : ",
        en: "Total duration of sessions : "
    },
    "%minutes minutes": {
        fr: "%minutes minutes",
        en: "%minutes minutes"
    }
}