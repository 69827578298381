export default {
   "Nom de la liste" : {
        fr: "Nom de la liste",
        en: "List name"
   },
   "Créer" : {
        fr: "Créer",
        en: "Create"
    },
    "Modèles :" : {
        fr: "Modèles :",
        en: "Templates :"
    },
    "Ajouter un utilisateur" : {
        fr: "Ajouter un utilisateur",
        en: "Add a user"
    },
    "Supprimer" : {
        fr: "Supprimer",
        en: "Delete"
    },
}