import React, { useState, useEffect } from 'react';
import './Users.scss';
import Modal from '../../components/Modal';
import UserForm from '../UserForm';
import User from '../../types/user';
import httpClient from '../../services/httpClient';
import ROUTES from '../../routes';
import { t } from '../../services/i18n';
import store, { Message } from '../../services/store';
import UserList from '../../types/user_list';
import Button from '../../components/Button';
import InputWithLabel from '../../components/InputWithLabel';
import SearchBar from '../../components/SearchBar';
import dayjs from 'dayjs';
import ListForm from '../ListForm';
import IconMagnifier from '../../../assets/images/icons/magnifier.svg';
import { useHistory } from 'react-router-dom';

export default () => {
  const [displayUserModal, setDisplayUserModal] = useState(false);
  const [displayListModal, setDisplayListModal] = useState(false);
  const [lists, setLists] = useState<UserList[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedList, setSelectedList] =
    useState<UserList>(undefined);
  const [selectedUser, setSelectedUser] = useState<User>(undefined);
  const [userToEdit, setUserToEdit] = useState<User>(undefined);
  const [filter, setFilter] = useState<string>(undefined);
  const [searchUsers, setSearchUsers] = useState('');
  const history = useHistory();

  useEffect(() => {
    fetchLists();
    fetchUsers();
  }, []);

  const fetchLists = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_USER_LISTS());

      setLists(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les listes')
      );
      console.warn(e);
    }
  };

  const fetchUsers = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_USERS({ license_id: store.store.JWT.license_id })
      );

      setUsers(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de récupérer les listes')
      );
      console.warn(e);
    }
  };

  const submitUser = async (user: User) => {
    try {
      let res = await httpClient.req(
        (user.id ? ROUTES.UPDATE_USER : ROUTES.CREATE_USER)({ user })
      );

      if (user.id) {
        let idx = users.findIndex((u) => u.id == user.id);

        users[idx] = res;
        setUsers([...users]);
      } else {
        setUsers([...users, res]);
      }
      setDisplayUserModal(false);
      setUserToEdit(undefined);
    } catch (e) {
      store.notify(
        Message.Error,
        t(
          "Impossible de sauvegarder l'utilisateur. L'email est peut-être déjà utilisé."
        )
      );
      console.warn(e);
    }
  };

  const displayableRole = (role: string) => {
    switch (role) {
      case 'correspondant':
        return 'Correspondant';
      case 'manager':
        return 'Manager';
      case 'top_manager':
        return 'Top Manager';
      case 'admin':
        return 'Admin';
      case 'superadmin':
        return 'Admin';
      default:
        return 'Apprenant';
    }
  };

  const removeUserFromSelectedList = async (user: User) => {
    try {
      let res = await httpClient.req(
        ROUTES.UPDATE_USER_LIST({
          id: selectedList.id,
          users: selectedList.users.filter((u) => u.id != user.id),
        })
      );

      setSelectedList(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t("Impossible de supprimer l'apprenant de la liste.")
      );
      console.warn(e);
    }
  };

  const addUserToSelectedList = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.UPDATE_USER_LIST({
          id: selectedList.id,
          users: [...selectedList.users, selectedUser],
        })
      );

      setSelectedUser(undefined);
      setSelectedList(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t("Impossible d'ajouter l'apprenant de la liste.")
      );
      console.warn(e);
    }
  };

  const editUser = (user: User) => {
    setUserToEdit(user);
    setDisplayUserModal(true);
  };

  const lockUser = (user: User) => {
    user.active = !user.active;
    submitUser(user);
  };

  const submitList = async (list: UserList & { file?: File }) => {
    try {
      let res = await httpClient.req(
        ROUTES.IMPORT_LIST({ file: list.file, name: list.name })
      );

      setLists([...lists, res]);
      setDisplayListModal(false);
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de sauvegarder la liste.')
      );
      console.warn(e);
    }
  };

  const deleteSelectedList = async () => {
    if (
      confirm(
        t(
          'Êtes-vous sûr de vouloir supprimer cette liste ? Cette action est irréversible.'
        )
      )
    ) {
      try {
        await httpClient.req(
          ROUTES.DELETE_USER_LIST({ id: selectedList.id })
        );

        setSelectedList(undefined);
        setLists(lists.filter((l) => l.id != selectedList.id));
      } catch (e) {
        store.notify(
          Message.Error,
          t('Impossible de supprimer la liste')
        );
        console.warn(e);
      }
    }
  };

  const deleteUser = async (user: User) => {
    if (
      confirm(
        t(
          "Êtes-vous sûr de vouloir supprimer cette utilisateur ? Cette action est irréversible. S'il s'agit d'un manager, ses formations vous seront réassignées."
        )
      )
    ) {
      try {
        await httpClient.req(ROUTES.DELETE_USER({ id: user.id }));

        setUsers(users.filter((u) => u.id != user.id));
      } catch (e) {
        store.notify(
          Message.Error,
          t("Impossible de supprimer l'utilisateur")
        );
        console.warn(e);
      }
    }
  };

  const titleForAllUsers = () => {
    switch (filter) {
      case 'admin':
        return t('Administrateurs');
      case 'top_manager':
        return t('Top Managers');
      case 'manager':
        return t('Managers');
      case 'correspondant':
        return t('Correspondants');
      case 'user':
        return t('Apprenants');
      default:
        return t('Tous les utilisateurs');
    }
  };

  return (
    <div className="Users">
      {displayUserModal && (
        <Modal dismiss={() => setDisplayUserModal(false)}>
          <UserForm user={userToEdit} onSubmit={submitUser} />
        </Modal>
      )}
      {displayListModal && (
        <Modal dismiss={() => setDisplayListModal(false)}>
          <ListForm onSubmit={submitList} />
        </Modal>
      )}
      <div className="Users__actions">
        <Button
          secondaryColor
          title={t('Télécharger une nouvelle liste')}
          onClick={() => setDisplayListModal(true)}
        />
      </div>
      <div className="Users__body">
        <div className="Users__bodyContent">
          <InputWithLabel
            label={t('Séléctionner la liste à afficher')}
            value={selectedList?.id}
            selectOptions={lists}
            selectLabelKey="name"
            selectValueKey="id"
            onChange={(v) =>
              setSelectedList(lists.find((l) => l.id == v))
            }
          />
          {selectedList && (
            <div className="Users__bodyContentList">
              <div className="Users__bodyContentListHeader">
                <div className="Users__bodyContentUsersHeaderLeft">
                  <div className="Users__bodyContentListHeaderTitle">
                    {selectedList.name}
                  </div>
                  <div className="Users__bodyContentListHeaderRecap">
                    <div className="Users__bodyContentListHeaderRecapEntry">
                      <i className="Users__bodyContentListHeaderRecapEntryIcon">
                        people
                      </i>
                      {selectedList.users.length}
                    </div>
                    <div className="Users__bodyContentListHeaderRecapEntry">
                      <i className="Users__bodyContentListHeaderRecapEntryIcon">
                        schedule
                      </i>
                      {dayjs(selectedList.createdAt).format('L')}
                    </div>
                    <div className="Users__bodyContentListHeaderRecapEntry">
                      <i className="Users__bodyContentListHeaderRecapEntryIcon">
                        person
                      </i>
                      {selectedList.user.firstName}{' '}
                      {selectedList.user.lastName}
                    </div>
                  </div>
                </div>
                <div className="Users__bodyContentUsersHeaderRight">
                  <Button
                    dangerColor
                    title={t('Supprimer')}
                    onClick={deleteSelectedList}
                  />
                </div>
              </div>
              <div className="Users__bodyContentListBody">
                <table>
                  <thead>
                    <tr>
                      <th>{t('Nom')}</th>
                      <th>{t('Email')}</th>
                      <th>{t('Role')}</th>
                      <th>{t('Manager')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedList.users
                      .filter((u) =>
                        filter
                          ? filter == 'admin'
                            ? u.role == 'admin' ||
                            u.role == 'superadmin'
                            : u.role == filter
                          : true
                      )
                      .sort((u1, u2) =>
                        u1.lastName >= u2.lastName ? 1 : -1
                      )
                      .map((u) => {
                        const manager = users.find(
                          (u2) => u2.id == u.managerId
                        );

                        return (
                          <tr key={u.id}>
                            <td>
                              {u.firstName} {u.lastName}
                            </td>
                            <td>{u.email}</td>
                            <td>{displayableRole(u.role)}</td>
                            <td>
                              {manager
                                ? `${manager.firstName} ${manager.lastName}`
                                : '-'}
                            </td>
                            <td>
                              <i
                                className="Users__bodyContentListBodyEntryRemove"
                                onClick={() =>
                                  removeUserFromSelectedList(u)
                                }
                              >
                                close
                              </i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="Users__bodyContentListBodyAdd">
                  <InputWithLabel
                    label={t('Ajouter un apprenant existant')}
                    value={selectedUser?.id}
                    selectOptions={users.filter(
                      (u) =>
                        !selectedList.users.find(
                          (u2) => u2.id == u.id
                        )
                    )}
                    selectLabelKey="email"
                    selectValueKey="id"
                    onChange={(v) =>
                      setSelectedUser(users.find((u) => u.id == v))
                    }
                  />
                  <Button
                    secondaryColor
                    title={t('Ajouter un utilisateur')}
                    onClick={() => addUserToSelectedList()}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="Users__bodyContentUsers">
            <div className="Users__bodyContentUsersHeader">
              <div className="Users__bodyContentUsersHeaderLeft">
                <div className="Users__bodyContentUsersHeaderTitle">
                  {titleForAllUsers()}
                </div>
                <div className="Users__bodyContentUsersHeaderRecap">
                  <div className="Users__bodyContentUsersHeaderRecapEntry"></div>
                </div>
                <SearchBar
                  icon={IconMagnifier}
                  value={searchUsers}
                  placeholder={t('Rechercher')}
                  onChange={(event) => {
                    setSearchUsers(event);
                  }}
                ></SearchBar>
              </div>
              <div className="Users__bodyContentUsersHeaderRight">
                <Button
                  secondaryColor
                  title={t('Créer un utilisateur')}
                  onClick={() => setDisplayUserModal(true)}
                />
              </div>
            </div>
            <div className="Users__bodyContentUsersBody">
              <table>
                <thead>
                  <tr>
                    <th>{t('Nom')}</th>
                    <th>{t('Email')}</th>
                    <th>{t('Role')}</th>
                    <th>{t('Manager')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users
                    .filter((u) =>
                      filter
                        ? filter == 'admin'
                          ? u.role == 'admin' ||
                          u.role == 'superadmin'
                          : u.role == filter
                        : true
                    )
                    .sort((u1, u2) =>
                      u1.lastName >= u2.lastName ? 1 : -1
                    )
                    .filter((user) => {
                      return (
                        user.firstName
                          .toLowerCase()
                          .includes(searchUsers.toLowerCase()) ||
                        user.lastName
                          .toLowerCase()
                          .includes(searchUsers.toLowerCase()) ||
                        user.email
                          .toLowerCase()
                          .includes(searchUsers.toLowerCase())
                      );
                    })
                    .map((u) => {
                      const manager = users.find(
                        (u2) => u2.id == u.managerId
                      );

                      return (
                        <tr
                          key={u.id}
                          onClick={(e) => {
                            if (
                              ((e.target as Element).tagName ==
                                'TD' &&
                                (e.target as Element).children[0] &&
                                (e.target as Element).children[0]
                                  .tagName == 'I') ||
                              (e.target as Element).tagName == 'I'
                            ) {
                              return null;
                            } else history.push(`/users/${u.id}`);
                          }}
                        >
                          <td>
                            {u.firstName} {u.lastName}
                          </td>
                          <td>{u.email}</td>
                          <td>{displayableRole(u.role)}</td>
                          <td>
                            {manager
                              ? `${manager.firstName} ${manager.lastName}`
                              : '-'}
                          </td>
                          <td>
                            <i
                              className="Users__bodyContentListBodyEntryEdit"
                              onClick={() => lockUser(u)}
                            >
                              {u.active ? 'lock_open' : 'lock'}
                            </i>
                            <i
                              className="Users__bodyContentListBodyEntryEdit"
                              onClick={() => editUser(u)}
                            >
                              edit
                            </i>
                            <i
                              className="Users__bodyContentListBodyEntryRemove"
                              onClick={() => deleteUser(u)}
                            >
                              close
                            </i>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div className="Users__bodyRecap">
          <div className="Users__bodyRecapTitle">
            {t('Récapitulatif (%count)', { count: users.length })}
          </div>
          <div
            className="Users__bodyRecapEntry"
            onClick={() => setFilter(undefined)}
          >
            <div className="Users__bodyRecapEntryTitle">{t('Total')}</div>
            <div className="Users__bodyRecapEntryValue">{users.length}</div>
          </div>
          <div
            className="Users__bodyRecapEntry"
            onClick={() => setFilter('admin')}
          >
            <div className="Users__bodyRecapEntryTitle">{t('Admins')}</div>
            <div className="Users__bodyRecapEntryValue">
              {
                users.filter((u) => u.role == 'admin' || u.role == 'superadmin')
                  .length
              }
            </div>
          </div>
          <div
            className="Users__bodyRecapEntry"
            onClick={() => setFilter('top_manager')}
          >
            <div className="Users__bodyRecapEntryTitle">
              {t('Top Managers')}
            </div>
            <div className="Users__bodyRecapEntryValue">
              {users.filter((u) => u.role == 'top_manager').length}
            </div>
          </div>
          <div
            className="Users__bodyRecapEntry"
            onClick={() => setFilter('manager')}
          >
            <div className="Users__bodyRecapEntryTitle">{t('Manager')}</div>
            <div className="Users__bodyRecapEntryValue">
              {users.filter((u) => u.role == 'manager').length}
            </div>
          </div>
          <div
            className="Users__bodyRecapEntry"
            onClick={() => setFilter('correspondant')}
          >
            <div className="Users__bodyRecapEntryTitle">
              {t('Correspondants')}
            </div>
            <div className="Users__bodyRecapEntryValue">
              {users.filter((u) => u.role == 'correspondant').length}
            </div>
          </div>
          <div
            className="Users__bodyRecapEntry"
            onClick={() => setFilter('user')}
          >
            <div className="Users__bodyRecapEntryTitle">{t('Apprenants')}</div>
            <div className="Users__bodyRecapEntryValue">
              {users.filter((u) => u.role == 'user').length}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
